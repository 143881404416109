// @flow
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import { useMutation } from '@apollo/client'
import { duplicateQuoteQuery, sendQuoteQuery } from 'quote/quote-queries'
import { SEARCH_PATH } from 'quote/quote-paths'

type Params = {
  id: string,
  history: Object,
}

type ReturnTypeUseDuplicateRequest = {
  quoteData: Object,
  isLoading: boolean,
  duplicateQuote: Function,
}

export const useDuplicateRequest = ({ id, history }: Params): ReturnTypeUseDuplicateRequest => {
  const quoteData = useQuoteDataFromCache(id)

  const [sendQuote, { loading: isLoadingSendQuote }] = useMutation(sendQuoteQuery, {
    onCompleted: () => {
      history.push({ pathname: SEARCH_PATH, state: { initialSearchState: 'monitoring' } })
    },
  })
  const [duplicateQuote, { loading: isLoadingDuplicateQuote }] = useMutation(duplicateQuoteQuery, {
    onCompleted: ({ duplicate }) => {
      sendQuote({ variables: { quoteId: duplicate.id, quoterGroupId: null } })
    },
  })

  return {
    quoteData,
    isLoading: isLoadingDuplicateQuote || isLoadingSendQuote,
    duplicateQuote,
  }
}
