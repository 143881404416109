// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Pagination from '@autodisol/ads-js/components/Pagination'
import { getFormattedDateByCountryCode } from 'utils/date'
import { getLanguage } from 'utils/language'
import Tooltip from '@material-ui/core/Tooltip'
import numeral from 'numeral'
import Badge from '@autodisol/ads-js/components/Badge'
import { TAG_QUOTE_IS_ORG_MULTIQUOTED } from '_client/config'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getFormattedPrice } from 'quote/cmp/Multiquotation/useMultiquotation'
import { MODES } from 'utils/constants/modes'
import { quotePath } from 'quote/quote-paths'
import IconButton from '@material-ui/core/IconButton'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'

import type { Mode } from 'types/modes'

const language = getLanguage()

const styles = ({ palette }) => ({
  loader: {
    textAlign: 'center',
  },
  color: { color: palette.error.main },
  loading__pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  current__quotation: { borderLeft: `5px solid ${palette.error.main}` },
})

type Props = {
  classes: Object,
  multiquotation: Object[],
  currentData?: Object,
  multiquotationResults: number,
  isLoadingMultiquotation: boolean,
  handlePagination: Function,
  currentPage: number,
  rowsPerPage: number,
  mode: Mode,
}

const getTotalKm = ({ contractKm, contractKmStart, pendingUpdate }) => {
  if (pendingUpdate) {
    return pendingUpdate?.contractKm + pendingUpdate?.contractKmStart > 0
      ? `${pendingUpdate?.contractKm + pendingUpdate?.contractKmStart} km`
      : '-'
  }

  return contractKm && contractKmStart && contractKm + contractKmStart > 0
    ? `${contractKm + contractKmStart} km`
    : '-'
}

const Multiquotation = ({
  classes,
  multiquotation,
  currentData,
  multiquotationResults,
  isLoadingMultiquotation,
  currentPage,
  rowsPerPage,
  handlePagination,
  mode,
}: Props) => {
  const { t } = useTranslation()

  const currentFormattedPrice = getFormattedPrice(currentData?.prices ?? [])
  const currentQuoterPrice =
    currentData?.prices && currentData?.prices.find(price => price.type === 'quoter')
  const currentQuotationDate = currentQuoterPrice?.createdAt?.date
  const currentEnquirerGroupName = currentData?.enquirerGroup?.name
  const currentReleaseDate = currentData?.releaseDate?.date
  const currentCategory = currentData?.category
  const currentDeliveryKm = currentData?.deliveryKm
    ? `${numeral(currentData.deliveryKm).format()} km`
    : '-'
  const currentDeliveryDate = currentData?.deliveryDate?.date
  const currentContractDateStart = currentData?.contractDateStart?.date
  const currentContractDuration = currentData?.contractDuration
  const currentDamagePrice = currentFormattedPrice?.bodywork
  const currentTags = (currentData?.tags ?? []).filter(
    element => element !== TAG_QUOTE_IS_ORG_MULTIQUOTED,
  )
  const currentCommitmentPrice =
    currentQuoterPrice && numeral(Math.round(parseInt(currentQuoterPrice.amount, 10))).format()
  const currentTradeinOffer =
    typeof currentFormattedPrice.tradein_offer === 'number'
      ? `${numeral(Math.round(parseInt(currentFormattedPrice.tradein_offer, 10))).format()} €`
      : '-'
  const currentStatus = currentData?.status
  const currentModel = currentData?.model

  const getLabel = status => (status ? t(`globals.allStatus.${status}`) : '-')

  return (
    <>
      <Table padding="dense" style={{ marginTop: 40 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('quote.quotationDate')}</TableCell>
            <TableCell align="center">{t('quote.enquirer')}</TableCell>
            <TableCell align="center">
              {t('quote.make')} <br /> / {t('quote.model')} <br /> / {t('quote.finishing')}
            </TableCell>
            <TableCell align="center">
              {t('quote.registrationDate')} <br /> / {t('quote.deliveryMileageShort')}
            </TableCell>
            <TableCell align="center">
              {currentCategory !== 'resval' ? (
                t('quote.deliveryDate')
              ) : (
                <>
                  {t('quote.contractDateStart')} <br /> / {t('quote.contractDuration')}
                </>
              )}
            </TableCell>
            <TableCell align="center">{t('quote.bodyRepairCosts')}</TableCell>
            <TableCell align="center">{t('quote.tags')}</TableCell>
            <TableCell align="center">
              {t('quote.commitmentPrice')}
              {mode !== MODES.resval && (
                <>
                  <br /> / {t('quote.commercialProposition')}
                </>
              )}
              <br /> / {t('quote.status')}
            </TableCell>
            <TableCell align="center">{t('quote.link')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.current__quotation}>
            <TableCell component="th" scope="row" align="center">
              {currentQuotationDate
                ? getFormattedDateByCountryCode(currentQuotationDate, language)
                : '-'}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              <Tooltip
                title={
                  currentEnquirerGroupName
                    ? t('quote.byUser', { user: currentEnquirerGroupName })
                    : ''
                }
                placement="top"
              >
                <div>{currentEnquirerGroupName ?? '-'}</div>
              </Tooltip>

              <span>{currentData?.enquirer?.fullname ?? '-'}</span>
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {currentData?.make} <br />
              {currentModel} <br />
              {currentData?.version} {currentData?.trimLevel}
            </TableCell>
            <TableCell align="center">
              {currentReleaseDate
                ? getFormattedDateByCountryCode(currentReleaseDate, language)
                : '-'}
              <br />
              {currentCategory !== 'resval'
                ? currentDeliveryKm
                : getTotalKm({
                    contractKm: currentData?.contractKm,
                    contractKmStart: currentData?.contractKmStart,
                    pendingUpdate: currentData?.pendingUpdate,
                  })}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {currentCategory !== 'resval' && (
                <>
                  {currentDeliveryDate
                    ? getFormattedDateByCountryCode(currentDeliveryDate, language)
                    : 'a'}
                </>
              )}
              {currentCategory === 'resval' && (
                <>
                  {currentContractDateStart
                    ? getFormattedDateByCountryCode(currentContractDateStart, language)
                    : '-'}
                  <br />
                  {currentContractDuration
                    ? `${currentContractDuration} ${t('globals.month')}`
                    : '-'}
                </>
              )}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {numeral(currentDamagePrice).format()} €
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {currentCategory === 'resval' ? (
                <Badge
                  tag="resval"
                  color={
                    multiquotation && multiquotation[0].category !== 'resval' ? 'red' : 'lightgrey'
                  }
                />
              ) : (
                currentTags.map((tag, index) => (
                  <span key={tag}>
                    <Badge tag={tag} color="lightgrey" />
                    {index < currentTags.length - 1 && ' / '}
                  </span>
                ))
              )}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {currentCommitmentPrice ? `${currentCommitmentPrice} €` : '-'} <br />
              {mode !== MODES.resval && (
                <>
                  {currentTradeinOffer} <br />
                </>
              )}
              {getLabel(currentStatus)}
            </TableCell>
            <TableCell />
          </TableRow>

          {isLoadingMultiquotation ? (
            <TableRow>
              <TableCell colSpan={9}>
                <div className={classes.loading__pagination}>
                  <CircularProgress thickness={4} size={30} />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            multiquotation.map(
              ({
                id,
                model,
                version,
                trimLevel,
                releaseDate,
                make,
                contractKm,
                contractKmStart,
                pendingUpdate,
                deliveryDate,
                contractDateStart,
                contractDuration,
                formattedPrices,
                status,
                quotationDate,
                enquirer,
                enquirerGroup,
                category,
                deliveryKm,
                tags,
              }) => {
                const deliveryKmFormatted = deliveryKm ? `${numeral(deliveryKm).format()} km` : null
                const tagsFiltered = tags.filter(
                  element => element !== TAG_QUOTE_IS_ORG_MULTIQUOTED,
                )
                const quoterPrice = formattedPrices?.quoter
                const tradeinOffer = formattedPrices?.tradein_offer

                return (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row" align="center">
                      {getFormattedDateByCountryCode(quotationDate, language)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Tooltip
                        title={
                          enquirerGroup?.name
                            ? t('quote.byUser', { user: enquirerGroup?.name })
                            : ''
                        }
                        placement="top"
                      >
                        <span>{enquirerGroup?.name ?? '-'}</span>
                      </Tooltip>

                      <div>{enquirer?.fullname ?? '-'}</div>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className={currentData?.make !== make ? classes.color : undefined}>
                        {make}
                      </span>

                      <br />

                      <span className={currentModel !== model ? classes.color : undefined}>
                        {model}
                      </span>

                      <br />

                      <span
                        className={currentData?.version !== version ? classes.color : undefined}
                      >
                        {version}
                      </span>

                      <br />

                      <span
                        className={currentData?.trimLevel !== trimLevel ? classes.color : undefined}
                      >
                        {trimLevel}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span
                        className={currentReleaseDate !== releaseDate?.date ? classes.color : ''}
                      >
                        {releaseDate
                          ? getFormattedDateByCountryCode(releaseDate.date, language)
                          : '-'}
                      </span>
                      <br />
                      {category !== 'resval' ? (
                        <span
                          className={
                            currentData?.deliveryKm !== deliveryKm ? classes.color : undefined
                          }
                        >
                          {numeral(deliveryKmFormatted).format()} km
                        </span>
                      ) : (
                        <span
                          className={
                            currentData?.contractKm !== contractKm ? classes.color : undefined
                          }
                        >
                          {getTotalKm({
                            contractKm,
                            contractKmStart,
                            pendingUpdate,
                          })}
                        </span>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {category !== 'resval' ? (
                        <span
                          className={
                            currentDeliveryDate !== deliveryDate?.date ? classes.color : undefined
                          }
                        >
                          {deliveryDate
                            ? getFormattedDateByCountryCode(deliveryDate.date, language)
                            : '-'}
                        </span>
                      ) : (
                        <>
                          <span
                            className={
                              currentContractDateStart !== contractDateStart?.date
                                ? classes.color
                                : undefined
                            }
                          >
                            {contractDateStart
                              ? getFormattedDateByCountryCode(contractDateStart.date, language)
                              : '-'}
                          </span>

                          <br />

                          <span
                            className={
                              currentContractDuration !== contractDuration
                                ? classes.color
                                : undefined
                            }
                          >
                            {contractDuration ? `${contractDuration} ${t('globals.month')}` : '-'}
                          </span>
                        </>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span
                        className={
                          formattedPrices.bodywork !== currentDamagePrice
                            ? classes.color
                            : undefined
                        }
                      >
                        {numeral(formattedPrices.bodywork).format()} €
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {category === 'resval' ? (
                        <Badge tag="resval" color="lightgrey" />
                      ) : (
                        tagsFiltered.map((tag, index) => (
                          <span key={tag}>
                            <Badge
                              tag={tag}
                              color={currentTags.includes(tag) ? 'lightgrey' : 'red'}
                            />
                            {index < tagsFiltered?.length - 1 && ' / '}
                          </span>
                        ))
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span
                        className={
                          currentQuoterPrice?.amount !== quoterPrice ? classes.color : undefined
                        }
                      >
                        {quoterPrice
                          ? `${numeral(Math.round(parseInt(quoterPrice, 10))).format()} €`
                          : '-'}
                      </span>

                      {mode !== MODES.resval && (
                        <>
                          <br />

                          <span
                            className={
                              currentFormattedPrice.tradein_offer !== tradeinOffer
                                ? classes.color
                                : undefined
                            }
                          >
                            {tradeinOffer
                              ? `${numeral(Math.round(parseInt(tradeinOffer, 10))).format()} €`
                              : '-'}
                          </span>
                        </>
                      )}

                      <br />

                      <span className={currentStatus !== status ? classes.color : undefined}>
                        {getLabel(status)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        target="_blank"
                        href={`${quotePath(id)}?fromMultiquotation=${currentData?.id ?? ''}`}
                      >
                        <OpenInBrowserIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              },
            )
          )}
        </TableBody>
      </Table>

      <Pagination
        updateResults={handlePagination()}
        count={multiquotationResults}
        style={{ marginBottom: 50 }}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
      />
    </>
  )
}

export default (withStyles(styles)(Multiquotation): any)
