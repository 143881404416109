// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { PLATE_EXAMPLES } from 'quote/quote-constant'
import Typography from '@material-ui/core/Typography'
import LabeledSeparator from '@autodisol/ads-js/components/LabeledSeparator'
import Multiselect from '@autodisol/ads-js/components/Multiselect'
import { getFilteredResults } from 'quote/cmp/DetailsForm/functions'
import { isInteger } from 'utils/number'
import FieldLabel from 'quote/cmp/FieldLabel'

import type { IdentificationMode } from 'quote/types'

type Props = {
  classes: Object,
  onChange: Function,
  field: Function,
  identifiedVehicle?: Object,
  identifyCountry?: string,
  energyList: Object[],
  gearboxList: Object[],
  regTypeList: Object[],
  handleSelectChange: Function,
  typeOfRegistrationDocumentValue?: Object | string,
  identificationMode: IdentificationMode,
}

const styles = () => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formFields: {
    width: '100%',
    maxWidth: 350,
  },
  formInput: {
    width: '100%',
    marginBottom: '2rem',
  },
  separator: {
    marginBottom: '2rem',
  },
  helper: {
    position: 'relative',
    top: '-1.7rem',
    paddingLeft: '1rem',
  },
  draftCreationHelper: {
    position: 'relative',
    top: '-1.7rem',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  draftCreationHelperSpan: {
    marginLeft: '1rem',
  },
  customOptGroup: {
    display: 'block',
    textTransform: 'capitalize',
    borderBottom: '1px solid grey',
    width: '100%',
  },
  itemWithPhase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'fit-content',
  },
})

const BikeForm = ({
  classes,
  identifiedVehicle,
  onChange,
  field,
  identifyCountry,
  energyList,
  gearboxList,
  regTypeList,
  handleSelectChange,
  typeOfRegistrationDocumentValue,
  identificationMode,
}: Props) => {
  const { t } = useTranslation()

  const { plate, vin } = identifiedVehicle ?? {}
  const isIdentificationAuto = identificationMode === 'auto'

  const handleChangePlate = e => {
    e.target.value = e.target.value.toUpperCase()
    onChange(e)
  }

  const isInTheRegTypeList = Boolean(
    regTypeList.find(
      element =>
        typeof typeOfRegistrationDocumentValue !== 'string' &&
        element.value === typeOfRegistrationDocumentValue?.value,
    ),
  )

  const handleChangeInputNumber = e => {
    const { value, name } = e.target

    if (isInteger(value)) {
      onChange({ target: { name, value } })
    }
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.formFields}>
        <LabeledSeparator title={t('globals.identification')} className={classes.separator} />

        <TextField
          name="plate"
          autoComplete="off"
          label={t('quote.plate')}
          value={plate ?? field('plate')}
          InputLabelProps={{ shrink: true }}
          disabled={!!plate}
          className={classes.formInput}
          placeholder={`${t('globals.exampleColon')} ${
            PLATE_EXAMPLES[identifyCountry] ?? PLATE_EXAMPLES.default
          }`}
          onChange={handleChangePlate}
        />

        <TextField
          name="vin"
          autoComplete="off"
          label={<FieldLabel label={t('quote.vin')} isRequired />}
          value={vin ?? field('vin')}
          InputLabelProps={{ shrink: true }}
          disabled={!!vin}
          className={classes.formInput}
          placeholder={`${t('globals.exampleColon')} 1ABCD23456E789123`}
          inputProps={{ maxLength: 17 }}
          onChange={onChange}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(E)' })}
        </Typography>

        <LabeledSeparator title={t('quote.identification.version')} className={classes.separator} />

        <TextField
          name="make"
          autoComplete="off"
          label={<FieldLabel label={t('quote.make')} isRequired />}
          placeholder={t('quote.identification.select.brand')}
          value={field('make')}
          InputLabelProps={{ shrink: true }}
          className={classes.formInput}
          onChange={onChange}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(D.1)' })}
        </Typography>

        <TextField
          name="model"
          autoComplete="off"
          label={<FieldLabel label={t('quote.model')} isRequired />}
          placeholder={t('quote.identification.select.model')}
          value={field('model')}
          InputLabelProps={{ shrink: true }}
          className={classes.formInput}
          onChange={onChange}
        />

        <TextField
          name="version"
          autoComplete="off"
          label={`${t('quote.identification.commercialName')}`}
          placeholder={t('quote.identification.commercialNamePlaceholder')}
          value={field('version')}
          InputLabelProps={{ shrink: true }}
          className={classes.formInput}
          onChange={onChange}
        />

        <LabeledSeparator title={t('quote.identification.engine')} className={classes.separator} />

        <Multiselect
          className={classes.formInput}
          label={
            <FieldLabel
              label={`${t('quote.identification.fuel')} / ${t('quote.identification.energy')}`}
              isRequired
            />
          }
          placeholder={t('quote.identification.select.energy')}
          defaultOptions={energyList}
          value={
            field('energy') && {
              value: field('energy'),
              label: t(`quote.energy.${field('energy')}`),
            }
          }
          handleChange={handleSelectChange('energy')}
          noOptionsMessage={() => t('quote.noResults')}
          getData={event => getFilteredResults({ list: energyList })(event)}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.3)' })}
        </Typography>

        <Multiselect
          className={classes.formInput}
          label={<FieldLabel label={`${t('quote.gearbox')}`} isRequired />}
          placeholder={t('quote.identification.select.gearbox')}
          defaultOptions={gearboxList}
          value={
            field('gearbox') && {
              value: field('gearbox'),
              label: t(`quote.gearboxes.${field('gearbox')}`),
            }
          }
          handleChange={handleSelectChange('gearbox')}
          noOptionsMessage={() => t('quote.noResults')}
          getData={event => getFilteredResults({ list: gearboxList })(event)}
        />

        <LabeledSeparator title={t('quote.identification.power')} className={classes.separator} />

        <TextField
          name="hpPower"
          label={
            <FieldLabel
              label={t('quote.identification.powerCh')}
              isRequired={isIdentificationAuto}
            />
          }
          value={field('hpPower')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.powerChDescription')}
        </Typography>

        <TextField
          name="kwPower"
          label={
            <FieldLabel
              label={t('quote.identification.powerKw')}
              isRequired={isIdentificationAuto}
            />
          }
          value={field('kwPower')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.2)' })}
        </Typography>

        <TextField
          name="taxPower"
          label={
            <FieldLabel
              label={t('quote.identification.fiscalPower')}
              isRequired={isIdentificationAuto}
            />
          }
          value={field('taxPower')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.6)' })}
        </Typography>

        <TextField
          name="displacement"
          label={<FieldLabel label={t('quote.identification.displacement')} isRequired />}
          value={field('displacement')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.1)' })}
        </Typography>

        <TextField
          name="co2"
          label={t('quote.identification.co2')}
          value={field('co2')}
          placeholder={t('quote.notSpecified')}
          className={classes.formInput}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(V.7)' })}
        </Typography>

        <LabeledSeparator title={t('quote.bodyWord')} className={classes.separator} />

        <TextField
          name="bodywork"
          label={<FieldLabel label={t('quote.bodyWord')} isRequired={isIdentificationAuto} />}
          placeholder={t('quote.bodyWordPlaceholder')}
          value={field('bodywork')}
          className={classes.formInput}
          autoComplete="off"
          onChange={onChange}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(J.3)' })}
        </Typography>

        <TextField
          name="seats"
          label={<FieldLabel label={t('quote.seatsNumber')} isRequired={isIdentificationAuto} />}
          value={field('seats')}
          className={classes.formInput}
          placeholder={t('quote.notSpecified')}
          autoComplete="off"
          onChange={handleChangeInputNumber}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(S.1)' })}
        </Typography>

        <TextField
          name="color"
          label={t('quote.color')}
          value={field('color')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={onChange}
        />

        <LabeledSeparator
          title={t('quote.identification.characteristics')}
          className={classes.separator}
        />

        <Multiselect
          className={classes.formInput}
          label={
            <FieldLabel label={t('quote.identification.typeOfRegistrationDocument')} isRequired />
          }
          placeholder={t('quote.identification.select.type')}
          defaultOptions={regTypeList}
          value={isInTheRegTypeList ? typeOfRegistrationDocumentValue : ''}
          handleChange={handleSelectChange('regType')}
          noOptionsMessage={() => t('quote.noResults')}
          getData={event => getFilteredResults({ list: regTypeList })(event)}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(J.1)' })}
        </Typography>

        <TextField
          name="firstRegDate"
          label={<FieldLabel label={t('quote.identification.inssuanceDate')} isRequired />}
          type="date"
          value={field('firstRegDate')}
          className={classes.formInput}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={onChange}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(B)' })}
        </Typography>

        <TextField
          name="lastRegDate"
          label={
            <FieldLabel label={t('quote.identification.lastRegistrationDocumentDate')} isRequired />
          }
          type="date"
          value={field('lastRegDate')}
          className={classes.formInput}
          style={{ minWidth: 250 }}
          placeholder={t('quote.notSpecifiedShe')}
          autoComplete="off"
          onChange={onChange}
        />
        <Typography variant="caption" color="secondary" className={classes.helper}>
          {t('quote.atTheBottomOfRegistrationDocument', '(B)')}
        </Typography>
      </div>
    </div>
  )
}

export default (withStyles(styles)(BikeForm): any)
