// @flow

/* eslint-disable no-alert */

import React, { useState, useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DamagesIcon from '@material-ui/icons/Build'
import PhotoIcon from '@material-ui/icons/AddAPhoto'
import DeliveryIcon from '@material-ui/icons/LocalShipping'
import HelpIcon from '@material-ui/icons/Help'
import SearchIcon from '@material-ui/icons/Search'
import WarningIcon from '@material-ui/icons/Warning'
import Alert from '@autodisol/ads-js/components/Alert'
import Damages from '@autodisol/ads-js/components/Damages/Damages'
import DamageList from '@autodisol/ads-js/components/Damages/DamageList'
import withDamages from '@autodisol/ads-js/components/Damages/with-damages'
import moment from 'moment'
import { resizeFile } from 'utils/resize'
import { isMobile } from 'react-device-detect'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import { useLazyQuery, useMutation } from '@apollo/client'
import withFields from 'sharyn/hocs/with-fields'
import withFilePickers from 'sharyn/hocs/with-file-pickers'
import { setUi } from 'sharyn/redux/actions'
import { useTranslation } from 'react-i18next'
import {
  TAG_IS_DAMAGED,
  TAG_HAS_VAT,
  TAG_IS_BROKEN,
  TAG_NEEDS_TIMING_BELT,
  TAG_HAS_SERVICE_RECORD,
  TAG_HAS_NO_VAT,
  TAG_IS_FOREIGN,
  TAG_PREVIOUS_AMBULANCE,
  TAG_PREVIOUS_TAXI,
  TAG_PREVIOUS_DRIVING_SCHOOL,
  TAG_PREVIOUS_LSR_LTR,
  TAG_IS_TESTED,
  TAG_IS_NOT_TESTED,
  TAG_STATE_BAD,
  TAG_STATE_AVERAGE,
  TAG_STATE_GOOD,
  TAG_STATE_VERY_GOOD,
  TAG_SPECIFIC_CUSTOMER_TARGET,
  TYPE_EL_BATTERY_HAS_WARRANTY,
  TYPE_EL_BATTERY_HAS_NO_WARRANTY,
  TYPE_EL_BATTERY_HAS_NO_POWER_CABLE,
  TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_2,
  TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_2,
  TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_3,
  TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_3,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO,
  TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE,
  TYPE_EL_BATTERY_HAS_POWER_CABLE,
  TAG_HAS_VEHICLE_INSPECTION,
  TAG_HAS_NO_VEHICLE_INSPECTION,
  TAG_HAS_ABS_EQUIPMENT,
  TAG_HAS_NO_ABS_EQUIPMENT,
  TAG_PREVIOUS_CIRCUIT_OFF_ROAD,
  TAG_PREVIOUS_DELIVERY,
  TAG_PREVIOUS_ROAD,
  TAG_PREVIOUS_NOT_CONCERNED,
  TAG_REG_CARD,
  TAG_TECHNICAL_CONTROL,
} from '_client/config'
import Delivery from 'quote/cmp/Delivery'
import DetailsForm from 'quote/cmp/DetailsForm'
import Questions from 'quote/cmp/Questions'
import Stepper from 'quote/cmp/Stepper'
import { SEARCH_PATH } from 'quote/quote-paths'
import {
  addDamageMutation,
  addQuotationMutation,
  autoEstimateQuoteMutation,
  correctQuoteMutation,
  removeDamageMutation,
  removeDocumentMutation,
  updateQuoteMutation,
  uploadDocumentMutation,
  getDefaultQuoterGroupIdQuery,
} from 'quote/quote-queries'
import { getQuestions } from 'quote/cmp/questionList'
import { setCurrentDraftQuoteId } from '_client/redux/actions'
import PhotosComponent from 'quote/cmp/Photos'
import i18next from 'services/translations/config'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { DOCUMENT_TYPE } from 'utils/constants/quote'
import { checkValidationRules, NO_REQUIRED_PICTURE } from 'quote/cmp/validationRules'
import DisplayCurrentMode from 'app/cmp/ModesMenu/DisplayCurrentMode'
import CloseQuotation from 'quote/cmp/QuoteSteps/Resval/CloseQuotation'
import findSchema, { getNameSchema } from 'quote/cmp/findSchema'
import { withRouter } from 'react-router-dom'
import { useCloseCaseQuote } from 'quote/cmp/QuoteSteps/Resval/CloseQuotation/useCloseCaseQuote'
import { CURRENT_DATE } from 'utils/constants/date'
import { getQuoteInput, getQuoteInputStandardBikeMode } from 'quote/cmp/QuoteSteps/functions'
import {
  IDENTIFICATION_VEHICLE_TYPE,
  RESIZE_MAX_WIDTH,
  RESIZE_MAX_HEIGHT,
  RESIZE_QUALITY,
  TYPES_MIME,
} from 'utils/constants'
import { getDocumentTags } from 'quote/functions'
import FieldLabel from 'quote/cmp/FieldLabel'

import type { Mode } from 'types/modes'
import type { IdentificationMode } from 'quote/types'

const getTags = ({ selectedVersion, fields, mode }) => {
  const tags = [
    fields.damaged === 'on' && TAG_IS_DAMAGED,
    fields.broken === 'off' && TAG_IS_BROKEN,
    fields.timingBelt === 'on' && TAG_NEEDS_TIMING_BELT,
    fields.serviceRecord === 'on' && TAG_HAS_SERVICE_RECORD,
    fields.vat === 'on' && TAG_HAS_VAT,
    fields.vat === 'off' && TAG_HAS_NO_VAT,
    fields.foreign === 'on' && TAG_IS_FOREIGN,
    fields.previous_use === TAG_PREVIOUS_ROAD && '',
    fields.previous_use === TAG_PREVIOUS_NOT_CONCERNED && '',
    fields.previous_use === TAG_PREVIOUS_AMBULANCE && TAG_PREVIOUS_AMBULANCE,
    fields.previous_use === TAG_PREVIOUS_TAXI && TAG_PREVIOUS_TAXI,
    fields.previous_use === TAG_PREVIOUS_DRIVING_SCHOOL && TAG_PREVIOUS_DRIVING_SCHOOL,
    fields.previous_use === TAG_PREVIOUS_LSR_LTR && TAG_PREVIOUS_LSR_LTR,
    fields.previous_use === TAG_PREVIOUS_CIRCUIT_OFF_ROAD && TAG_PREVIOUS_CIRCUIT_OFF_ROAD,
    fields.previous_use === TAG_PREVIOUS_DELIVERY && TAG_PREVIOUS_DELIVERY,
    fields.hasBeenTried === TAG_IS_TESTED && TAG_IS_TESTED,
    fields.hasBeenTried === TAG_IS_NOT_TESTED && TAG_IS_NOT_TESTED,
    fields.rating === TAG_STATE_BAD && TAG_STATE_BAD,
    fields.rating === TAG_STATE_AVERAGE && TAG_STATE_AVERAGE,
    fields.rating === TAG_STATE_GOOD && TAG_STATE_GOOD,
    fields.rating === TAG_STATE_VERY_GOOD && TAG_STATE_VERY_GOOD,
    fields.specific_customer_target === 'on' && TAG_SPECIFIC_CUSTOMER_TARGET,
    fields.hasAbs === 'on' && TAG_HAS_ABS_EQUIPMENT,
    fields.hasAbs === 'off' && TAG_HAS_NO_ABS_EQUIPMENT,
    fields.hasVehicleInspection === 'on' && TAG_HAS_VEHICLE_INSPECTION,
    fields.hasVehicleInspection === 'off' && TAG_HAS_NO_VEHICLE_INSPECTION,
    fields.batteryNumber === TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE &&
      TYPE_EL_BATTERY_NUMBER_SUPPLIED_ONE,
    fields.batteryNumber === TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO &&
      TYPE_EL_BATTERY_NUMBER_SUPPLIED_TWO,
    fields.batteryNumber === TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE &&
      TYPE_EL_BATTERY_NUMBER_SUPPLIED_MORE,
  ]
  if (['EL', 'GH', 'EH'].includes(selectedVersion?.energy || fields.energy)) {
    tags.push(fields?.batteryType)
    if (fields?.hasWarranty) {
      tags.push(
        fields?.hasWarranty === 'on'
          ? TYPE_EL_BATTERY_HAS_WARRANTY
          : TYPE_EL_BATTERY_HAS_NO_WARRANTY,
      )
    }

    if (fields?.hasChargingCable === 'on' && mode !== MODES.standard_bike) {
      tags.push(
        fields?.hasPowerCableMode2 === 'on'
          ? TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_2
          : TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_2,
        fields?.hasPowerCableMode3 === 'on'
          ? TYPE_EL_BATTERY_HAS_POWER_CABLE_MODE_3
          : TYPE_EL_BATTERY_HAS_NO_POWER_CABLE_MODE_3,
      )
    }

    if (fields?.hasChargingCable === 'on' && mode === MODES.standard_bike) {
      tags.push(TYPE_EL_BATTERY_HAS_POWER_CABLE)
    }

    if (fields?.hasChargingCable === 'off') {
      tags.push(TYPE_EL_BATTERY_HAS_NO_POWER_CABLE)
    }
  }

  return tags
}

const onSelectChange =
  ({ onChange }) =>
  e => {
    if (e.target.value === 'none') {
      e.target.value = ''
    }
    onChange(e)
  }

const handleSetDamage =
  ({
    addDamage,
    currentDraftQuoteId,
    damages,
    setDamage,
    setIsDamageLoading,
    identifiedVehicle: i,
    isEditionMode,
    isCorrectionMode,
    removeDamageQuery,
    deleteDamage,
    isCloseMode,
  }) =>
  async (key, val) => {
    setIsDamageLoading(true)
    if (damages[key]) {
      await removeDamageQuery({
        variables: {
          damageInput: {
            quote: isEditionMode || isCorrectionMode || isCloseMode ? i?.id : currentDraftQuoteId,
            zone: key,
            type: damages[key].type,
          },
        },
      })

      deleteDamage(damages[key])
    }

    const resp = await addDamage({
      variables: {
        damageInput: {
          quote: isEditionMode || isCorrectionMode || isCloseMode ? i?.id : currentDraftQuoteId,
          zone: key,
          type: val,
        },
      },
    })

    setDamage(key, val, resp?.data?.add_damage?.id)
    if (isMobile && !NO_REQUIRED_PICTURE.includes(key)) {
      const damagePictureInput = document.getElementById(`damage-list-add-picture-${key}`)
      damagePictureInput && damagePictureInput.click()
    }
    setIsDamageLoading(false)
  }

const handleDeleteDamage =
  ({
    removeDamageQuery,
    currentDraftQuoteId,
    damages,
    deleteDamage,
    isEditionMode,
    isCorrectionMode,
    identifiedVehicle: i,
  }) =>
  async key => {
    await removeDamageQuery({
      variables: {
        damageInput: {
          quote: isEditionMode || isCorrectionMode ? i?.id : currentDraftQuoteId,
          zone: key,
          type: damages[key].type,
        },
      },
    })

    deleteDamage(key)
  }

const handleDamagePictureUpload =
  ({
    addPicture,
    currentDraftQuoteId,
    damages,
    field,
    identifiedVehicle: i,
    resizeEnabled,
    uploadDocument,
    pictureMaxWidth,
    pictureMaxHeight,
    pictureQuality,
  }) =>
  async (key, e) => {
    e.preventDefault()

    const { files } = e.target

    if (!files) return

    let actualDamageId
    let image: File = files[0]

    if (resizeEnabled && image.type !== TYPES_MIME.PDF) {
      const imageResize = await resizeFile({
        file: image,
        pictureMaxWidth,
        pictureMaxHeight,
        pictureQuality,
      })
      if (imageResize.size < image.size) image = imageResize
    }

    if (i?.damages) {
      actualDamageId = i && i.damages.find(elem => elem.zone === key)
    }

    const resp = await uploadDocument({
      variables: {
        documentInput: {
          type: DOCUMENT_TYPE.DMG,
          tags: ['damage', 'picture'],
          vin: i?.vin ?? field('vin'),
          quoteId: i?.id ?? currentDraftQuoteId,
          damageId: damages[key].extra ? damages[key].extra : actualDamageId?.id,
        },
        file: image,
      },
    })

    if (resp?.data?.upload_document) {
      addPicture(key, {
        id: resp?.data?.upload_document.id,
        name: resp?.data?.upload_document.filename,
        caption: i18next.t(`damage.type.${damages[key].type}`),
        src: resp?.data?.upload_document.uri,
      })
    }
  }

const handleDamagePictureDelete =
  ({ removeDocument }) =>
  picture =>
    removeDocument({
      variables: {
        id: picture.id,
      },
    })

const handleSave =
  ({
    currentDraftQuoteId,
    identifiedVehicle: i,
    fields,
    updateQuote,
    selectedMake,
    selectedSubFamily,
    selectedModel,
    selectedVersion,
    mode,
  }) =>
  async e => {
    e.preventDefault()
    const crashedComment = fields.crashedComment
      ? fields.crashedComment.replace(i?.oldCrashedComment, '')
      : null
    const mechanicalComment =
      fields.damagesComment && fields.damagesComment.replace(i?.oldDamagesComment, '')
    const generalComment = fields.finalComment ?? ''
    const tags = getTags({ selectedVersion, fields, mode })
    const quoteInput =
      mode === MODES.standard_bike
        ? getQuoteInputStandardBikeMode({
            mode,
            i,
            currentDraftQuoteId,
            fields,
            tags,
            generalComment,
            mechanicalComment,
            crashedComment,
          })
        : getQuoteInput({
            mode,
            i,
            currentDraftQuoteId,
            fields,
            selectedMake,
            tags,
            generalComment,
            mechanicalComment,
            crashedComment,
            selectedVersion,
            selectedSubFamily,
            selectedModel,
          })

    await updateQuote({
      variables: {
        quoteInput,
      },
    })
  }

const handleCreate =
  ({
    addQuotation,
    currentDraftQuoteId,
    identifiedVehicle: i,
    fields,
    dispatch,
    routerHistory,
    defaultQuoterGroupId,
    defaultQuoterGroup,
    isCorrectionMode,
    selectedMake,
    selectedSubFamily,
    selectedModel,
    selectedVersion,
    estimateAutomatic,
    setIsEstimateLoading,
    setIsSendingQuote,
    autoEstimateQuote,
    updateQuote,
    correctQuote,
    mode,
    isCloseMode,
    closeCaseQuote,
  }) =>
  async e => {
    try {
      e.preventDefault()
      const tags = getTags({ selectedVersion, fields, mode })

      if (isCloseMode) {
        closeCaseQuote({ i, fields, tags })
        return
      }

      if (estimateAutomatic) {
        setIsEstimateLoading(true)
      } else {
        setIsSendingQuote(true)
      }
      await handleSave({
        currentDraftQuoteId,
        identifiedVehicle: i,
        fields,
        selectedMake,
        selectedSubFamily,
        selectedModel,
        selectedVersion,
        updateQuote,
        mode,
      })(e)

      if (estimateAutomatic) {
        await autoEstimateQuote({
          variables: {
            id: i?.id ?? currentDraftQuoteId,
          },
        })
      } else if (isCorrectionMode) {
        await correctQuote({
          variables: { id: i?.id ? i?.id : currentDraftQuoteId },
        })
      } else {
        await addQuotation({
          variables: {
            quoteId: i?.id ? i?.id : currentDraftQuoteId,
            quoterGroupId: defaultQuoterGroup || defaultQuoterGroupId,
          },
        })
      }
      dispatch(setCurrentDraftQuoteId(null))
      routerHistory.push({
        pathname: SEARCH_PATH,
        state: { initialSearchState: 'monitoring' },
      })
      setIsEstimateLoading(false)
      setIsSendingQuote(false)
    } catch (error) {
      setIsSendingQuote(false)
    }
  }

const formValidator = data => () => checkValidationRules(data)

const handleAlertOpening =
  ({ setAlertOptions }) =>
  options => {
    setAlertOptions({ ...options, isOpened: true })
  }

const handleAlertClosing =
  ({ setAlertOptions, alertOptions }) =>
  () => {
    setAlertOptions({ ...alertOptions, isOpened: false })
  }

const handleSubmit =
  ({
    addQuotation,
    damages,
    alertOptions,
    setAlertOptions,
    currentDraftQuoteId,
    identifiedVehicle,
    fields,
    dispatch,
    routerHistory,
    defaultQuoterGroupId,
    defaultQuoterGroup,
    isEditionMode,
    isCorrectionMode,
    selectedMake,
    selectedSubFamily,
    selectedModel,
    selectedVersion,
    isManual,
    estimateAutomatic,
    setIsEstimateLoading,
    setIsSendingQuote,
    autoEstimateQuote,
    updateQuote,
    correctQuote,
    mode,
    isCloseMode,
    closeCaseQuote,
  }) =>
  e => {
    e.preventDefault()

    if (Object.keys(damages).length === 0 && (mode === MODES.standard || isCloseMode)) {
      handleAlertOpening({ setAlertOptions })({
        title: 'Attention',
        content: (
          <div>
            {i18next.t('quote.identification.noDamagesWarning')} <br />
            <span style={{ fontWeight: 700 }}>
              {i18next.t('quote.identification.confirmSendingQuote')}
            </span>
          </div>
        ),
        handlePositiveClose: () => {
          handleAlertClosing({ setAlertOptions, alertOptions })()
          handleCreate({
            addQuotation,
            currentDraftQuoteId,
            identifiedVehicle,
            fields,
            dispatch,
            routerHistory,
            defaultQuoterGroupId,
            defaultQuoterGroup,
            isEditionMode,
            isCorrectionMode,
            selectedMake,
            selectedSubFamily,
            selectedModel,
            selectedVersion,
            isManual,
            estimateAutomatic,
            setIsEstimateLoading,
            setIsSendingQuote,
            autoEstimateQuote,
            updateQuote,
            correctQuote,
            mode,
            isCloseMode,
            closeCaseQuote,
          })(e)
        },
        handleNegativeClose: () => {
          handleAlertClosing({ setAlertOptions, alertOptions })()
        },
      })
    } else {
      handleCreate({
        addQuotation,
        currentDraftQuoteId,
        identifiedVehicle,
        fields,
        dispatch,
        routerHistory,
        defaultQuoterGroupId,
        defaultQuoterGroup,
        isEditionMode,
        isCorrectionMode,
        selectedMake,
        selectedSubFamily,
        selectedModel,
        selectedVersion,
        isManual,
        estimateAutomatic,
        setIsEstimateLoading,
        setIsSendingQuote,
        autoEstimateQuote,
        updateQuote,
        correctQuote,
        mode,
        isCloseMode,
        closeCaseQuote,
      })(e)
    }
  }

const handleStepChange =
  ({ setActiveStep, dispatch, steps }) =>
  i => {
    setActiveStep(i)
    dispatch(setUi({ subtitle: steps[i].label }))
  }

const QuoteStepsJSX = ({
  classes: css,
  identifiedVehicle: i,
  field,
  fields,
  setFields,
  setField,
  onChange,
  damages,
  isEditionMode,
  isCorrectionMode,
  filePicker,
  currentDraftQuoteId,
  dispatch,
  identifiedVehicle,
  setDamage,
  deleteDamage,
  addPicture,
  routerHistory,
  defaultQuoterGroupId,
  rights,
  identifyCountry,
  furtherStepsDisabled,
  pictureMaxWidth,
  pictureMaxHeight,
  pictureQuality,
  resizeEnabled,
  mode,
  isCloseMode,
  history,
  questionsFromConfiguration,
  identificationMode,
}: {
  classes: Object,
  field: Function,
  fields: Object,
  setFields: Function,
  setField: Function,
  identifiedVehicle?: Object,
  onChange: Function,
  damages: Object,
  isEditionMode?: boolean,
  isCorrectionMode?: boolean,
  isDeletingPicture?: boolean,
  filePicker: any,
  currentDraftQuoteId?: string,
  dispatch: Function,
  setDamage: Function,
  deleteDamage: Function,
  addPicture: Function,
  routerHistory: Object,
  defaultQuoterGroupId?: string,
  identifyCountry?: string,
  rights: string[],
  furtherStepsDisabled?: boolean,
  pictureMaxWidth: number,
  pictureMaxHeight: number,
  pictureQuality: number,
  resizeEnabled: boolean,
  mode: Mode,
  isCloseMode?: boolean,
  history: Object,
  questionsFromConfiguration: Object[],
  identificationMode: IdentificationMode,
}) => {
  const { t } = useTranslation()
  const isManual = !identifiedVehicle
  const [activeStep, setActiveStep] = useState(0)

  const [uploadedPictures, setUploadedPictures] = useState([])
  const [isDamageLoading, setIsDamageLoading] = useState(false)
  const [alertOptions, setAlertOptions] = useState({ isOpened: false })

  const [selectedMake, setSelectedMake] = useState(null)
  const [selectedSubFamily, setSelectedSubFamily] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [defaultQuoterGroup, setdefaultQuoterGroup] = useState('')
  const [isEstimateLoading, setIsEstimateLoading] = useState(false)
  const [isSendingQuote, setIsSendingQuote] = useState(false)
  const [isUpdateQuote, setUpdateQuote] = useState(false)
  const [autoEstimateQuote] = useMutation(autoEstimateQuoteMutation)
  const [updateQuote, { loading: isSavingQuote }] = useMutation(updateQuoteMutation)
  const [addDamage] = useMutation(addDamageMutation)
  const [removeDamageQuery] = useMutation(removeDamageMutation)
  const [addQuotation] = useMutation(addQuotationMutation)
  const [uploadDocument, { loading: isLoadingUploadDocument }] = useMutation(uploadDocumentMutation)
  const [removeDocument, { loading: isLoadingRemoveDocument }] = useMutation(removeDocumentMutation)
  const [getDefaultQuoterGroupId] = useLazyQuery(getDefaultQuoterGroupIdQuery, {
    // eslint-disable-next-line
    onCompleted: ({ get_default_quoter_group_id }) =>
      setdefaultQuoterGroup(get_default_quoter_group_id),
  })
  const [correctQuote] = useMutation(correctQuoteMutation)
  const { closeCaseQuote, isLoadingCloseCaseQuote } = useCloseCaseQuote(history, field)

  const doors = isCloseMode ? i?.doors : selectedVersion?.doors
  const carType = isCloseMode ? i?.type : selectedMake?.type
  const schemaType = mode === MODES.standard_bike ? IDENTIFICATION_VEHICLE_TYPE.MOTO : carType

  const hasRequiredPhotos = (): boolean => {
    const REQUIRED_COMMON_PICTURES = [
      'picture_view_three_quarter_front_left',
      'picture_view_three_quarter_back_right',
      'picture_view_running_dashboard',
    ]
    const REQUIRED_CAR_PICTURES = [...REQUIRED_COMMON_PICTURES, 'picture_view_dashboard_from_back']
    const REQUIRED_BIKE_PICTURES = [
      ...REQUIRED_COMMON_PICTURES,
      'picture_view_three_quarter_front_right',
      'picture_view_three_quarter_back_left',
    ]

    const REQUIRED_PICTURES =
      mode === MODES.standard_bike ? REQUIRED_BIKE_PICTURES : REQUIRED_CAR_PICTURES

    if (mode === MODES.standard_bike) {
      const regCardPicture = uploadedPictures.find(photo => photo.type === TAG_REG_CARD)

      return (
        uploadedPictures.filter(photo => REQUIRED_PICTURES.includes(photo.tags)).length === 5 &&
        Boolean(regCardPicture)
      )
    }

    return uploadedPictures.filter(photo => REQUIRED_PICTURES.includes(photo.tags)).length === 4
  }

  useEffect(() => {
    if (isEditionMode) {
      getDefaultQuoterGroupId()
    }

    if (isManual) {
      return
    }

    // -- Remplissage des informations générales
    const newFields: Object = { ...identifiedVehicle }
    newFields.firstRegDate = newFields.firstRegDate?.date
      ? moment(newFields.firstRegDate.date).format('YYYY-MM-DD')
      : null
    newFields.lastRegDate = newFields.lastRegDate?.date
      ? moment(newFields.lastRegDate.date).format('YYYY-MM-DD')
      : null

    if (identifiedVehicle && identifiedVehicle?.tags?.length) {
      const questions = getQuestions(mode)

      questions.forEach(question => {
        if (question.choices) {
          question.choices.forEach(choice => {
            const tagIndexChoice = identifiedVehicle.tags.findIndex(tag => tag === choice.tag)
            if (tagIndexChoice > -1) {
              newFields[question.name] = identifiedVehicle.tags[tagIndexChoice]
            }
          })

          return
        }

        if (
          (question.name !== 'broken' &&
            question.name !== 'hasChargingCable' &&
            identifiedVehicle.tags.includes(question.tag)) ||
          (question.name === 'hasChargingCable' &&
            !identifiedVehicle.tags.includes(question.tag)) ||
          (question.name === 'broken' && !identifiedVehicle.tags.includes(question.tag))
        ) {
          newFields[question.name] = 'on'
        } else {
          newFields[question.name] = 'off'
        }

        if (question.subQuestions && newFields.hasChargingCable === 'on') {
          question.subQuestions.forEach(subQuestion => {
            const tagIndexSub = identifiedVehicle.tags.findIndex(tag => tag === subQuestion.subTag)

            if (tagIndexSub > -1) {
              newFields[subQuestion.subName] = 'on'
              return
            }

            newFields[subQuestion.subName] = 'off'
          })
        }
      })

      questionsFromConfiguration.forEach(({ name }) => {
        newFields[name] = identifiedVehicle.tags.includes(name) ? 'on' : 'off'
      })
      if (identifiedVehicle.tags.findIndex(tag => tag === TAG_IS_TESTED) > -1) {
        newFields.hasBeenTried = TAG_IS_TESTED
      } else if (identifiedVehicle.tags.findIndex(tag => tag === TAG_IS_NOT_TESTED) > -1) {
        newFields.hasBeenTried = TAG_IS_NOT_TESTED
      }
      if (identifiedVehicle.tags.findIndex(tag => tag === TAG_STATE_GOOD) > -1) {
        newFields.rating = TAG_STATE_GOOD
      }
      if (identifiedVehicle.tags.findIndex(tag => tag === TAG_STATE_AVERAGE) > -1) {
        newFields.rating = TAG_STATE_AVERAGE
      }
      if (identifiedVehicle.tags.findIndex(tag => tag === TAG_STATE_BAD) > -1) {
        newFields.rating = TAG_STATE_BAD
      }
      if (identifiedVehicle.tags.findIndex(tag => tag === TAG_STATE_VERY_GOOD) > -1) {
        newFields.rating = TAG_STATE_VERY_GOOD
      }
    }
    if (identifiedVehicle && identifiedVehicle?.oldCrashedComment) {
      newFields.crashedComment = identifiedVehicle.oldCrashedComment
    }

    // -- Remplissage de l'onglet photos
    if (identifiedVehicle && identifiedVehicle?.documents) {
      setUploadedPictures(
        identifiedVehicle.documents
          .filter(document =>
            [
              DOCUMENT_TYPE.PHOTO,
              DOCUMENT_TYPE.REG_CARD,
              DOCUMENT_TYPE.DMG,
              DOCUMENT_TYPE.TECH_CHECK,
            ].includes(document.type),
          )
          .map(picture => ({
            id: picture.id,
            name: picture.filename,
            tags: picture.tags[0] ?? [],
            src: picture.uri,
            type: picture.type,
            mime: picture.mime,
          })),
      )
    }

    // -- Remplissage de l'onglet degats
    if (identifiedVehicle && identifiedVehicle?.damages) {
      identifiedVehicle.damages.forEach(damage => {
        setDamage(damage.zone, damage.type)
        damage.documents &&
          damage.documents.forEach(document => {
            addPicture(damage.zone, {
              id: document.id,
              name: document.filename,
              src: document.uri,
            })
          })
      })
    }

    if (identifiedVehicle && identifiedVehicle.prices) {
      const mechanicalPrice = identifiedVehicle.prices.find(e => e.type === 'mechanical')
      newFields.mechanicalCost = mechanicalPrice && parseInt(mechanicalPrice.amount, 10)
    }

    if (identifiedVehicle && identifiedVehicle?.oldDamagesComment) {
      newFields.damagesComment = identifiedVehicle.oldDamagesComment
    }
    // -- Remplissage de l'onglet livraison
    if (newFields.deliveryDate) {
      const deliveryDate = moment(newFields.deliveryDate.date).format('YYYY-MM-DD')

      newFields.deliveryDate =
        deliveryDate < CURRENT_DATE && identifiedVehicle?.status === 'draft'
          ? CURRENT_DATE
          : deliveryDate
    }

    if (newFields.vehicle) {
      const { __typename, ...vehicleData } = newFields.vehicle
      newFields.vehicle = vehicleData
    }

    if (mode === MODES.standard_bike) {
      const makeData = i?.makes?.[0]
      const versionData = i?.makes?.[0]?.families?.[0]?.subFamilies?.[0]?.models?.[0]?.versions?.[0]
      const modelData = i?.makes?.[0]?.families?.[0]?.subFamilies?.[0]?.models?.[0]

      const {
        catalogId,
        make,
        model,
        version,
        energy,
        gearbox,
        hpPower,
        kwPower,
        taxPower,
        displacement,
        co2,
        body,
        seats,
        color,
        regCo2,
        regColor,
      } = identifiedVehicle ?? {}

      newFields.catalogId = catalogId ?? versionData?.id
      newFields.make = make ?? makeData?.name ?? ''
      newFields.type = makeData?.type ?? IDENTIFICATION_VEHICLE_TYPE.MOTO
      newFields.model = model ?? modelData?.name ?? ''
      newFields.version = version ?? versionData?.name ?? ''
      newFields.energy = energy ?? versionData?.energy ?? ''
      newFields.gearbox = gearbox ?? versionData?.gearbox ?? ''
      newFields.kwPower = kwPower ?? versionData?.kwPower ?? ''
      newFields.hpPower = hpPower ?? versionData?.hpPower ?? ''
      newFields.taxPower = taxPower ?? versionData?.taxPower ?? ''
      newFields.displacement = displacement ?? versionData?.displacement ?? ''
      newFields.co2 = co2 ?? regCo2 ?? null
      newFields.bodywork = body ?? i?.regBody ?? ''
      newFields.seats = seats ?? versionData?.seats ?? ''
      newFields.color = color ?? regColor
    }

    setFields(newFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifiedVehicle])

  const reversedDamageKeys = Object.keys(damages).reverse()
  const reversedDamages = {}
  reversedDamageKeys.forEach(k => {
    reversedDamages[k] = damages[k]
  })
  const isBikeMode = mode === MODES.standard_bike

  const handleAddDocument = type => async e => {
    const { files } = e.target

    if (!files) return

    let image: File = files[0]

    if (resizeEnabled && image.type !== TYPES_MIME.PDF) {
      const imageResize = await resizeFile({
        file: image,
        pictureMaxWidth,
        pictureMaxHeight,
        pictureQuality,
      })
      if (imageResize.size < image.size) image = imageResize
    }

    const resp = await uploadDocument({
      variables: {
        documentInput: {
          type,
          tags: getDocumentTags(null, type),
          vin: i?.vin ?? field('vin'),
          quoteId: i?.id ?? currentDraftQuoteId,
        },
        file: image,
      },
    })

    const picture = resp?.data?.upload_document

    if (picture) {
      setUploadedPictures(prevState => [
        ...prevState,
        {
          id: picture.id,
          name: picture.filename,
          tags: picture.tags[0],
          src: picture.uri,
          type: picture.type,
          mime: picture.mime,
        },
      ])
    }
  }

  const handleDeleteDocument = (pictureId?: string, callback?: Function) => async () => {
    const resp = await removeDocument({
      variables: {
        id: pictureId,
      },
    })

    if (resp?.data?.remove_document?.successful) {
      setUploadedPictures(uploadedPictures.filter(v => v.id !== pictureId))
      if (callback) callback()
    }
  }

  const vehicleInspectionDocuments = uploadedPictures.filter(
    element => element.type === TAG_TECHNICAL_CONTROL,
  )

  const steps = [
    ...(!isCloseMode
      ? [
          {
            label: t('quote.vehicleData'),
            content: (
              <DetailsForm
                {...{
                  field,
                  fields,
                  setField,
                  onChange,
                  isManual,
                  isEditionMode,
                  isCorrectionMode,
                  identifiedVehicle: i,
                  selectedMake,
                  setSelectedMake,
                  selectedSubFamily,
                  setSelectedSubFamily,
                  selectedModel,
                  setSelectedModel,
                  selectedVersion,
                  setSelectedVersion,
                  identifyCountry,
                  setUpdateQuote,
                  mode,
                  identificationMode,
                }}
              />
            ),
            icon: SearchIcon,
          },
        ]
      : []),
    ...(mode !== MODES.resval
      ? [
          {
            label: t('quote.questions'),
            content: (
              <Questions
                {...{
                  field,
                  setField,
                  isLoadingRemoveDocument,
                  onChange,
                  selectedVersion,
                  questionsFromConfiguration,
                  mode,
                  vehicleInspectionDocuments,
                  handleDeleteDocument,
                  handleAddDocument,
                  isLoadingUploadDocument,
                }}
              />
            ),
            icon: HelpIcon,
          },
        ]
      : []),
    {
      label: t('quote.photos'),
      content: (
        <PhotosComponent
          {...{
            hasRequiredPhotos: hasRequiredPhotos(),
            schema: findSchema(schemaType, doors),
            selectedVersion,
            uploadedPictures,
            setUploadedPictures,
            field,
            isEditionMode,
            isCorrectionMode,
            currentDraftQuoteId,
            identifiedVehicle,
            pictureMaxWidth,
            pictureMaxHeight,
            pictureQuality,
          }}
        />
      ),
      icon: PhotoIcon,
    },
    ...(mode !== MODES.resval || isCloseMode
      ? [
          {
            label: t('quote.damages'),
            content: (
              <div className={css.container}>
                <>
                  {Object.keys(damages).length === 0 && (
                    <Typography variant="body1" className={css.hintZone}>
                      {t('quote.identification.damageLayoutHelper')}
                    </Typography>
                  )}
                  <div style={{ maxWidth: 600, width: '100%' }}>
                    {doors || isBikeMode ? (
                      <Damages
                        schema={getNameSchema(schemaType, doors)}
                        {...{ damages, isDamageLoading }}
                        setDamage={handleSetDamage({
                          addDamage,
                          currentDraftQuoteId,
                          damages,
                          setDamage,
                          setIsDamageLoading,
                          identifiedVehicle,
                          isEditionMode,
                          isCorrectionMode,
                          removeDamageQuery,
                          deleteDamage,
                          isCloseMode,
                        })}
                        responsiveInline
                        editable
                        unicolor
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <WarningIcon style={{ fill: '#FFA500', marginRight: '1rem' }} />
                        {t('quote.identification.infoStep')}
                      </div>
                    )}
                  </div>
                </>
                <Divider />
                <div className={css.formFields}>
                  {formValidator({
                    field,
                    damages,
                    selectedVersion,
                    hasRequiredPhotos: hasRequiredPhotos(),
                    mode,
                    identifiedVehicle: i,
                    isCloseMode,
                  })().steps[isCloseMode ? 1 : 3].errors?.missingPictures && (
                    <Typography color="error" variant="caption" style={{ marginBottom: 20 }}>
                      {t('quote.mandatoryDamagePicture')}
                    </Typography>
                  )}

                  <DamageList
                    {...{ setDamage }}
                    damages={reversedDamages}
                    editable
                    handleDelete={handleDeleteDamage({
                      removeDamageQuery,
                      currentDraftQuoteId,
                      damages,
                      deleteDamage,
                      isEditionMode,
                      isCorrectionMode,
                      identifiedVehicle,
                    })}
                    handlePictureAddition={handleDamagePictureUpload({
                      addPicture,
                      currentDraftQuoteId,
                      damages,
                      dispatch,
                      field,
                      identifiedVehicle,
                      resizeEnabled,
                      uploadDocument,
                      filePicker,
                      pictureMaxWidth,
                      pictureMaxHeight,
                      pictureQuality,
                    })}
                    handlePictureDelete={handleDamagePictureDelete({ removeDocument })}
                  />

                  <TextField
                    name="mechanicalCost"
                    label={<FieldLabel label={t('quote.mechanicalCost')} isRequired />}
                    value={field('mechanicalCost')}
                    placeholder={t('quote.identification.estimatedCostsInEuros')}
                    type="number"
                    style={{ margin: '20px 0px 40px 0', width: '100%' }}
                    inputProps={{ min: 0 }}
                    autoComplete="off"
                    {...{ onChange }}
                  />
                  <TextField
                    name="damagesComment"
                    label={<FieldLabel label={t('quote.mechanicalFeedback')} isRequired />}
                    value={field('damagesComment')}
                    placeholder={
                      mode === MODES.standard_bike
                        ? t('quote.identification.bike.mechanicalFeedbackPlaceholder')
                        : t('quote.identification.mechanicalFeedbackPlaceholder')
                    }
                    type="text"
                    multiline
                    rows="4"
                    InputLabelProps={{ classes: { root: css.textInputLabel } }}
                    InputProps={{ classes: { root: css.textInput } }}
                    style={{ marginBottom: 40, width: '100%' }}
                    {...{ onChange }}
                    className={css.formControl}
                  />
                  <Typography
                    color="secondary"
                    variant="caption"
                    style={{ position: 'relative', top: '-2rem' }}
                  >
                    {t('quote.mandatoryComment')}
                  </Typography>
                  <FormControl className={css.formInput}>
                    <InputLabel htmlFor="rating">
                      <FieldLabel
                        label={t('quote.identification.generalAppreciation')}
                        isRequired
                      />
                    </InputLabel>
                    <Select
                      id="rating"
                      name="rating"
                      value={field('rating') !== '' ? field('rating') : 'none'}
                      onChange={onSelectChange({ onChange })}
                    >
                      <MenuItem value="none" disabled>
                        {t('globals.select')}
                      </MenuItem>
                      <MenuItem value={TAG_STATE_BAD}>
                        {t('quote.identification.badCondition')}
                      </MenuItem>
                      <MenuItem value={TAG_STATE_AVERAGE}>
                        {t('quote.identification.averageCondition')}
                      </MenuItem>
                      <MenuItem value={TAG_STATE_GOOD}>
                        {t('quote.identification.goodCondition')}
                      </MenuItem>
                      <MenuItem value={TAG_STATE_VERY_GOOD}>
                        {t('quote.identification.veryGoodCondition')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    key="hasBeenTried"
                    style={{
                      marginTop: 40,
                    }}
                  >
                    <FormLabel component="legend">
                      <FieldLabel
                        label={t('quote.identification.questions.hasBeenTried')}
                        isRequired
                      />
                    </FormLabel>
                    <RadioGroup
                      value={field('hasBeenTried')}
                      name="hasBeenTried"
                      row
                      {...{ onChange }}
                    >
                      <FormControlLabel
                        value={TAG_IS_TESTED}
                        control={
                          <Radio
                            color="primary"
                            classes={{ colorSecondary: css.darkRadio, checked: css.darkRadio }}
                          />
                        }
                        label={t('globals.yes')}
                      />
                      <FormControlLabel
                        value={TAG_IS_NOT_TESTED}
                        control={
                          <Radio
                            color="primary"
                            style={{ marginLeft: 80 }}
                            classes={{ colorSecondary: css.darkRadio, checked: css.darkRadio }}
                          />
                        }
                        label={t('globals.no')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ),
            icon: DamagesIcon,
          },
        ]
      : []),
    ...(!isCloseMode
      ? [
          {
            label: t('quote.delivery'),
            content: (
              <Delivery
                {...{ field, onChange, identifiedVehicle: i, mode }}
                formValidator={formValidator({
                  field,
                  damages,
                  selectedVersion,
                  hasRequiredPhotos: hasRequiredPhotos(),
                  mode,
                  isCloseMode,
                })}
              />
            ),
            icon: DeliveryIcon,
          },
        ]
      : []),
    ...(isCloseMode
      ? [
          {
            label: t('quote.identification.closing'),
            content: (
              <CloseQuotation
                field={field}
                onChange={onChange}
                contractKmStart={i?.contractKmStart}
                sentInFleetAt={i?.sentInFleetAt?.date}
                formValidator={formValidator({
                  field,
                  damages,
                  hasRequiredPhotos: hasRequiredPhotos(),
                  isCloseMode,
                  identifiedVehicle: i,
                })}
              />
            ),
            icon: DeliveryIcon,
          },
        ]
      : []),
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleStepChange({ dispatch, setActiveStep, steps })(0), [])

  return (
    <div>
      <Alert {...alertOptions} setAlertOptions />
      <form
        onSubmit={handleSubmit({
          damages,
          alertOptions,
          setAlertOptions,
          currentDraftQuoteId,
          identifiedVehicle,
          fields,
          dispatch,
          routerHistory,
          defaultQuoterGroupId,
          defaultQuoterGroup,
          isEditionMode,
          isCorrectionMode,
          selectedMake,
          selectedSubFamily,
          selectedModel,
          selectedVersion,
          isManual,
          estimateAutomatic: false,
          setIsEstimateLoading,
          setIsSendingQuote,
          autoEstimateQuote,
          updateQuote,
          addQuotation,
          correctQuote,
          mode,
          isCloseMode,
          closeCaseQuote,
        })}
      >
        <DisplayCurrentMode mode={mode} />
        <Stepper
          {...{
            steps,
            activeStep,
            isSendingQuote,
            isEditionMode,
            isCorrectionMode,
            identifiedVehicle,
            isEstimateLoading,
            rights,
            furtherStepsDisabled,
            isSavingQuote,
            isUpdateQuote,
            isCloseMode,
            isLoadingCloseCaseQuote,
            field,
            fields,
            identificationMode,
          }}
          setActiveStep={handleStepChange({ dispatch, steps, setActiveStep })}
          formValidator={formValidator({
            field,
            damages,
            selectedVersion,
            hasRequiredPhotos: hasRequiredPhotos(),
            mode,
            identifiedVehicle: i,
            isCloseMode,
            questionsRequired: questionsFromConfiguration.map(element => element.name),
            hasVehicleInspectionDocument: Boolean(
              uploadedPictures.find(element => element.type === TAG_TECHNICAL_CONTROL),
            ),
            identificationMode,
          })}
          handleAutomaticSubmit={handleSubmit({
            damages,
            alertOptions,
            setAlertOptions,
            currentDraftQuoteId,
            identifiedVehicle,
            fields,
            dispatch,
            routerHistory,
            defaultQuoterGroupId,
            defaultQuoterGroup,
            isEditionMode,
            isCorrectionMode,
            selectedMake,
            selectedSubFamily,
            selectedModel,
            selectedVersion,
            isManual,
            estimateAutomatic: true,
            setIsEstimateLoading,
            setIsSendingQuote,
            autoEstimateQuote,
            updateQuote,
            addQuotation,
            correctQuote,
            mode,
            isCloseMode,
            closeCaseQuote,
          })}
          handleSave={handleSave({
            currentDraftQuoteId,
            identifiedVehicle: i,
            fields,
            selectedMake,
            selectedSubFamily,
            selectedModel,
            selectedVersion,
            updateQuote,
            mode,
          })}
          hasRequiredPhotos={hasRequiredPhotos()}
          numberPhotosAdded={
            uploadedPictures.filter(element => {
              if (mode === MODES.standard_bike) {
                return (
                  element.type === DOCUMENT_TYPE.PHOTO || element.type === DOCUMENT_TYPE.REG_CARD
                )
              }

              return element.type === DOCUMENT_TYPE.PHOTO
            }).length
          }
          style={{ marginBottom: 40 }}
          disabled={!selectedVersion}
          mode={mode}
        />
      </form>
    </div>
  )
}

const QuoteStepsCmp = withStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hintZone: {
    marginBottom: 40,
    textAlign: 'center',
  },
  textInput: {
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid black',
    paddingLeft: 5,
    paddingTop: '2rem',
  },
  textInputLabel: {
    transform: 'translate(0, -0.5rem)',
  },
  darkRadio: {
    color: `${theme.palette.secondary.dark} !important`,
  },
  textStyle: { fontWeight: 'bold', textAlign: 'center' },
  buttonFileIconWrapper: {
    width: 30,
    height: 30,
    position: 'absolute',
    background: '#FFF',
    borderRadius: '100%',
    top: 145,
    right: 0,
  },
  buttonFileIcon: {
    color: '#E2EAF0',
    width: 'auto',
    height: 'auto',
  },
  buttonFileIconValidate: {
    color: '#03B564',
    width: 'auto',
    height: 'auto',
  },
  formFields: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      maxWidth: 350,
    },
  },
}))(QuoteStepsJSX)

const QuoteSteps: any = compose(
  withRedux(({ asyncMap, user, env, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      userInfo: user,
      isDeletingPicture: asyncMap.pictureDeletion,
      rights: user.data.rights,
      furtherStepsDisabled: user.data.elements?.furtherStepsDisabled,
      pictureMaxWidth: env.PICTURE_MAX_WIDTH ?? RESIZE_MAX_WIDTH,
      pictureMaxHeight: env.PICTURE_MAX_HEIGHT ?? RESIZE_MAX_HEIGHT,
      pictureQuality: env.PICTURE_QUALITY ?? RESIZE_QUALITY,
      resizeEnabled: env.RESIZE_ENABLED,
      mode: currentMode,
      isCloseMode: router?.location?.state?.mode === 'close',
      questionsFromConfiguration: (user?.data?.config?.quote?.quote?.tags?.questions ?? []).filter(
        element => element.enabled,
      ),
    }
  }),
  withFields(),
  withFilePickers(),
  withDamages(),
  withRouter,
)(QuoteStepsCmp)

export default QuoteSteps
