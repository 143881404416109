// @flow
import React, { useEffect, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Progress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import SitesFilterSection from '@autodisol/ads-js/components/Filters/Sites'
import withStyles from '@material-ui/core/styles/withStyles'
import Alert from '@autodisol/ads-js/components/Alert'
import Card from '@autodisol/ads-js/components/Card'
import Button from '@autodisol/ads-js/components/CustomButton'
import Element from '@autodisol/ads-js/components/Quote/ActionPanel/Element'
import QuoteInfos from 'quote/cmp/QuoteActionsPanel/QuoteInfos'
import { connect as withRedux } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { notify } from 'sharyn/redux/actions'
import withFields from 'sharyn/hocs/with-fields'
import isEither from 'sharyn/util/is-either'
import { useMutation, useLazyQuery } from '@apollo/client'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import { editQuotePath, SEARCH_PATH } from 'quote/quote-paths'
import {
  addQuotationMutation,
  cancelQuoteMutation,
  assignQuoteQuery,
  listSitesQuery,
  rejectQuoteQuery,
  validateAssignmentMutation,
  rejectAssignmentMutation,
  getDefaultAuctionGroupIdQuery,
} from 'quote/quote-queries'
import { getPrice, checkPriceConfig } from '_client/util'
import i18next from 'services/translations/config'
import QuoterPriceNotMarged from 'quote/cmp/QuoteActionsPanel/QuoterPriceNotMarged'
import CommercialPropositionPrice from 'quote/cmp/QuoteActionsPanel/CommercialPropositionPrice'
import { Grid } from '@material-ui/core'
import { getSubStatus } from '@autodisol/ads-js/utils/functions'
import CommercialProposition from 'quote/cmp/CommercialProposition'
import ActualPurchasePriceModal from 'quote/cmp/QuoteActionsPanel/ActualPurchasePriceModal'
import List from '@autodisol/ads-js/components/List'
import {
  QUOTE_STATUS_DRAFT,
  QUOTE_STATUS_WAITING,
  QUOTE_STATUS_QUOTED,
  QUOTE_STATUS_CHANGES_REQUESTED,
} from '_client/config'
import AcceptAction from 'quote/cmp/QuoteActionsPanel/AcceptAction'
import DuplicateRequestAction from 'quote/cmp/QuoteActionsPanel/DuplicateRequestAction'
import { isDateDifferenceLessThan } from 'utils/date'
import { SEVENTY_TWO_HOURS } from 'utils/constants/date'

const CURRENT_DATE = new Date()

type QuoteActionPanelProps = {
  classes: Object,
  history: Object,
  id: string,
  status: string,
  quoterPrice?: Object,
  quoterPriceNotMarged?: Object,
  tradeinPrice?: Object,
  tradeinOffer?: Object,
  dispatch: Function,
  field: Function,
  setField: Function,
  onChange: Function,
  rights: string[],
  plate?: string,
  askCorrectionComment?: string,
  expired?: boolean,
  archived?: boolean,
  displayPrice?: Object,
  prices?: Object[],
  withdrawalSite?: Object,
  billingSite?: Object,
  enquirerGroup: Object,
  pricingComment?: string,
  setStatus: Function,
  isPendingUpdate?: boolean,
  hasAlreadyBeenAppreciated: boolean,
  userId: string,
  canEnterACommercialProposal: boolean,
  canEnterTheActualPurchasePrice: boolean,
  tradeinOfferConfiguration: Object,
  quotedAt?: string,
}

const handleAlertOpening =
  ({ setAlertOptions }) =>
  options => {
    setAlertOptions({ isOpened: true, ...options })
  }

const handleAlertClosing =
  ({ setAlertOptions, alertOptions }) =>
  () => {
    setAlertOptions({ isOpened: false, ...alertOptions })
  }

const handleWithdrawal =
  ({
    field,
    onChange,
    setAlertOptions,
    alertOptions,
    id,
    getDefaultAuctionGroupId,
    dispatch,
    setField,
    withdrawalSite,
    billingSite,
    enquirerGroup,
    listBillingSites,
    listWithdrawalSites,
    assignQuote,
  }) =>
  async () => {
    let initialBillingSitesResponse
    let initialWithdrawalSitesResponse

    const { data: defaultAuctionGroupIdData } = await getDefaultAuctionGroupId()

    const auctionGroupId = defaultAuctionGroupIdData?.get_default_auction_group_id

    if (!auctionGroupId) {
      dispatch(
        notify({
          title: i18next.t('error.general.anErrorOccured'),
          message: i18next.t('error.general.pleaseTryAgainByReloadingThePage'),
        }),
      )

      return
    }

    await listBillingSites({
      variables: {
        filters: {
          types: ['billing'],
          parentGroup: enquirerGroup?.root?.id,
        },
      },
    }).then(({ data }) => {
      initialBillingSitesResponse = data.list_sites
    })
    await listWithdrawalSites({
      variables: {
        filters: {
          types: ['withdrawal'],
          parentGroup: enquirerGroup?.root?.id,
        },
      },
    }).then(({ data }) => {
      initialWithdrawalSitesResponse = data.list_sites
    })

    const withdrawalChoice = new Promise((resolve, reject) => {
      handleAlertOpening({ setAlertOptions })({
        title: i18next.t('quote.requestWithdrawal.notification.title'),
        content: (
          <WithdrawalModalCmp
            {...{
              field,
              onChange,
              setField,
              initialBillingSitesResponse,
              initialWithdrawalSitesResponse,
              enquirerGroup,
              withdrawalSite,
              billingSite,
            }}
          />
        ),
        informationMessage: (
          <div style={{ fontStyle: 'italic', marginTop: 20, textAlign: 'center' }}>
            {i18next.t('quote.mentionOfSalesMandate')}
          </div>
        ),
        positiveLabel: i18next.t('quote.sendDemand'),
        negativeLabel: i18next.t('globals.cancel'),
        handlePositiveClose: () => {
          resolve()
          handleAlertClosing({ setAlertOptions, alertOptions })()
        },
        handleNegativeClose: () => {
          reject()
          handleAlertClosing({ setAlertOptions, alertOptions })()
        },
        isPositiveButtonDisabled: () =>
          field('withdrawalSite') === '' || field('billingSite') === '',
      })
    })
    withdrawalChoice
      .then(() => {
        assignQuote({
          variables: {
            id,
            auctionGroupId,
            billingSiteId: field('billingSite'),
            withdrawalSiteId: field('withdrawalSite'),
          },
        })
      })
      .catch(() => {})
  }

const QuoteActionsPanelJSX = ({
  classes: css,
  id,
  status,
  quoterPrice,
  quoterPriceNotMarged,
  tradeinPrice,
  tradeinOffer,
  dispatch,
  field,
  setField,
  onChange,
  rights,
  history,
  plate,
  askCorrectionComment,
  expired,
  archived,
  displayPrice,
  prices,
  withdrawalSite,
  billingSite,
  enquirerGroup,
  pricingComment,
  setStatus,
  isPendingUpdate,
  hasAlreadyBeenAppreciated,
  userId,
  canEnterACommercialProposal,
  canEnterTheActualPurchasePrice,
  tradeinOfferConfiguration,
  quotedAt,
}: QuoteActionPanelProps) => {
  const { t } = useTranslation()

  const [alertOptions, setAlertOptions] = useState({ isOpened: false })

  const [rejectQuote, { loading: isRejectLoading }] = useMutation(rejectQuoteQuery, {
    variables: {
      quoteId: id,
    },
    // eslint-disable-next-line
    onCompleted: ({ reject_quote }) => {
      setStatus(reject_quote.status)
    },
  })

  const [addQuotation, { loading: isAddQuotationLoading }] = useMutation(addQuotationMutation, {
    onCompleted: () => {
      history.push({
        pathname: SEARCH_PATH,
      })
    },
  })
  const [cancelQuote, { loading: isQuoteCancelling }] = useMutation(cancelQuoteMutation, {
    onCompleted: () => {
      history.push({
        pathname: SEARCH_PATH,
      })
    },
  })
  const [validateAssignment, { loading: isAcceptAssignLoading }] = useMutation(
    validateAssignmentMutation,
    {
      onCompleted: () => {
        history.push({
          pathname: SEARCH_PATH,
          state: { initialSearchState: 'wait_assign_valid' },
        })
      },
    },
  )
  const [rejectAssignment, { loading: isRejectAssignLoading }] = useMutation(
    rejectAssignmentMutation,
    {
      onCompleted: () => {
        history.push({
          pathname: SEARCH_PATH,
          state: { initialSearchState: 'wait_assign_valid' },
        })
      },
    },
  )

  const [assignQuote, { loading: loadAssignQuote }] = useMutation(assignQuoteQuery, {
    // eslint-disable-next-line
    onCompleted: ({ assign_quote }) => {
      setStatus(assign_quote.status)
      dispatch(
        notify({
          title: i18next.t('quote.requestWithdrawal.notification.title'),
          subTitle: i18next.t('quote.requestWithdrawal.notification.subtitle'),
          message: i18next.t('quote.requestWithdrawal.notification.message', { plate }),
        }),
      )
      history.push({
        pathname: SEARCH_PATH,
        state: { initialSearchState: 'assigned' },
      })
    },
  })

  const [listBillingSites, { loading: loadBillingSites }] = useLazyQuery(listSitesQuery)

  const [listWithdrawalSites, { loading: loadWithdrawalSites }] = useLazyQuery(listSitesQuery)

  const [getDefaultAuctionGroupId, { loading: loadingDefaultAuctionGroupId }] = useLazyQuery(
    getDefaultAuctionGroupIdQuery,
    {
      context: {
        notifyError: false,
      },
    },
  )

  const b2cAdvisedPrice = getPrice('b2c_advised', prices, displayPrice, rights)

  const subStatus = getSubStatus(expired, archived)
  const isDisplayUpdateFleetStatus = isPendingUpdate && status === 'waiting'
  const canSeeTradeinOffer = checkPriceConfig({
    displayConfiguration: displayPrice,
    rights,
    type: 'tradein_offer',
    prices,
  })

  const canDuplicateQuote =
    isDateDifferenceLessThan({
      dateToCheck: quotedAt,
      currentDate: CURRENT_DATE,
      durationInMs: SEVENTY_TWO_HOURS,
    }) && status === QUOTE_STATUS_QUOTED

  const queryParams = new URLSearchParams(history?.location?.search)
  const fromMultiquotation = queryParams.get('fromMultiquotation') ?? undefined
  const isFromMultiquotation = Boolean(fromMultiquotation)

  return expired !== true ? (
    <Card>
      <>
        <QuoteInfos
          status={status}
          subStatus={subStatus}
          withdrawalSite={withdrawalSite}
          isDisplayUpdateFleetStatus={isDisplayUpdateFleetStatus}
          hasAlreadyBeenAppreciated={hasAlreadyBeenAppreciated}
        />
        {b2cAdvisedPrice && b2cAdvisedPrice?.amount && !quoterPrice?.isEstimate && (
          <Element
            title={t('quote.advisedPrice')}
            content={`${numeral(b2cAdvisedPrice?.amount).format()} €`}
          />
        )}

        {!quoterPrice && b2cAdvisedPrice && (status === 'quoted' || status === 'onhold_opening') ? (
          <>
            <div className={css.noCommitmentPrice} style={{ marginBottom: '1rem' }}>
              {t(`quote.message_no_commitment_price.${status}`)}
            </div>
            {status !== 'onhold_opening' && !isFromMultiquotation && (
              <Button
                variant="contained"
                colorType="yellow"
                height="short"
                isLoading={isAddQuotationLoading}
                onClick={() => {
                  addQuotation({
                    variables: {
                      quoteId: id,
                    },
                  })
                }}
                fullWidth
              >
                {t('quote.askCommitmentPrice')}
              </Button>
            )}
            {status === 'onhold_opening' && !isFromMultiquotation && (
              <>
                <Divider style={{ margin: '1.5rem 0', width: '100%' }} />
                <Button
                  variant="contained"
                  colorType="error"
                  height="short"
                  isLoading={isQuoteCancelling}
                  onClick={() => {
                    handleAlertOpening({ setAlertOptions })({
                      title: 'Attention',
                      content: (
                        <>
                          <span style={{ fontWeight: 700 }}>
                            {t('quote.confirmCancellingQuote')}
                          </span>
                          <br />
                          <em>{t('quote.confirmCancellingQuoteDetails')}</em>
                        </>
                      ),
                      handlePositiveClose: () => {
                        handleAlertClosing({ setAlertOptions, alertOptions })()
                        cancelQuote({
                          variables: {
                            id,
                          },
                        })
                      },
                      handleNegativeClose: () => {
                        handleAlertClosing({ setAlertOptions, alertOptions })()
                      },
                    })
                  }}
                  fullWidth
                >
                  {t('quote.cancelQuote')}
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Element
              title={quoterPrice?.isEstimate ? t('quote.estimate') : t('quote.commitmentPrice')}
              content={quoterPrice ? `${numeral(quoterPrice?.amount).format()} €` : '-'}
            />
            <QuoterPriceNotMarged
              quoterPrice={quoterPriceNotMarged}
              rights={rights}
              style={{ textAlign: 'center', marginTop: 24 }}
            />
            <CommercialPropositionPrice
              price={tradeinOffer}
              rights={rights}
              style={{ textAlign: 'center', marginTop: 24 }}
            />
            {tradeinPrice?.amount && (
              <List
                style={{ textAlign: 'center' }}
                data={[
                  {
                    title: `${t('quote.actualPurchasePrice.title')}: `,
                    content: tradeinPrice.amount
                      ? `${numeral(tradeinPrice.amount).format()} €`
                      : '—',
                  },
                ]}
              />
            )}
          </>
        )}
      </>
      {pricingComment && (
        <Grid style={{ textAlign: 'center', marginTop: 10 }}>{pricingComment}</Grid>
      )}

      {!isFromMultiquotation && <Divider style={{ margin: '1.5rem 0', width: '100%' }} />}

      {canDuplicateQuote && !isFromMultiquotation && <DuplicateRequestAction id={id} />}

      {status !== 'rejected' && !isFromMultiquotation ? (
        <div className={css.actionButtons}>
          {rights.includes('valid_assign_quote') && status === 'wait_assign_valid' && (
            <>
              <Button
                colorType="green"
                height="short"
                variant="contained"
                fullWidth
                isLoading={isAcceptAssignLoading}
                disabled={isRejectAssignLoading}
                style={{ marginBottom: 10 }}
                onClick={() => {
                  validateAssignment({
                    variables: {
                      id,
                    },
                  })
                }}
              >
                {t('quote.waitAssignValidButtonAccept')}
              </Button>
              <Button
                colorType="error"
                height="short"
                variant="contained"
                fullWidth
                isLoading={isRejectAssignLoading}
                disabled={isAcceptAssignLoading}
                onClick={() => {
                  rejectAssignment({
                    variables: {
                      id,
                    },
                  })
                }}
              >
                {t('quote.waitAssignValidButtonReject')}
              </Button>
            </>
          )}
          {canSeeTradeinOffer &&
            canEnterACommercialProposal &&
            (status === 'quoted' || status === 'accepted') && (
              <CommercialProposition
                rights={rights}
                quoteId={id}
                b2cAdvisedPrice={b2cAdvisedPrice}
                quoterPrice={quoterPrice}
                tradeinOffer={tradeinOffer}
                userId={userId}
                tradeinOfferConfiguration={tradeinOfferConfiguration}
              />
            )}

          {![
            QUOTE_STATUS_DRAFT,
            QUOTE_STATUS_WAITING,
            QUOTE_STATUS_QUOTED,
            QUOTE_STATUS_CHANGES_REQUESTED,
          ].includes(status) &&
            rights.includes('view_group_quote') &&
            canEnterTheActualPurchasePrice && (
              <ActualPurchasePriceModal
                actionButtonContent={
                  typeof tradeinPrice?.amount === 'number'
                    ? t('quote.actualPurchasePrice.change')
                    : t('quote.actualPurchasePrice.add')
                }
                modalValidateButtonContent={t('quote.save')}
              />
            )}

          {rights.includes('accept_reject_quote') && status === 'quoted' && quoterPrice && (
            <>
              {canEnterTheActualPurchasePrice ? (
                <ActualPurchasePriceModal
                  actionButtonContent={t('quote.accept')}
                  modalValidateButtonContent={t('quote.save')}
                  isDisabled={status !== 'quoted'}
                  isDisplaySkipThisStepButton
                  mustExecuteAcceptQuoteQuery
                />
              ) : (
                <AcceptAction
                  id={id}
                  content={t('quote.accept')}
                  isDisabled={status !== 'quoted'}
                />
              )}

              <Button
                className={['draft', 'quoted', 'waiting'].includes(status) ? '' : css.hiddenButton}
                colorType="error"
                height="short"
                variant="contained"
                onClick={() => rejectQuote()}
                fullWidth
                disabled={isEither(status, 'waiting', 'draft')}
                isLoading={isRejectLoading}
              >
                {t('quote.reject')}
              </Button>
            </>
          )}
          {status === 'changes_requested' && (
            <>
              <Typography className={css.panelLabel} style={{ margin: 0 }}>
                {t('quote.correctionRequestColon')}
              </Typography>
              <Typography variant="body2" className={css.requirements}>
                {askCorrectionComment}
              </Typography>

              <Button
                variant="contained"
                colorType="yellow"
                height="short"
                component={Link}
                to={{
                  pathname: editQuotePath(id),
                  state: { mode: 'correction' },
                }}
                fullWidth
              >
                {t('quote.editDemand')}
              </Button>
            </>
          )}
          {rights.includes('assign_auctioner') &&
            rights.includes('add_site_quote') &&
            status === 'accepted' && (
              <>
                <Button
                  colorType="green"
                  height="short"
                  variant="contained"
                  onClick={handleWithdrawal({
                    dispatch,
                    field,
                    onChange,
                    setAlertOptions,
                    alertOptions,
                    id,
                    getDefaultAuctionGroupId,
                    setField,
                    withdrawalSite,
                    billingSite,
                    enquirerGroup,
                    listBillingSites,
                    listWithdrawalSites,
                    assignQuote,
                  })}
                  fullWidth
                  isLoading={
                    loadBillingSites ||
                    loadWithdrawalSites ||
                    loadAssignQuote ||
                    loadingDefaultAuctionGroupId
                  }
                >
                  {t('quote.requestWithdrawal.notification.title')}
                </Button>
                <Button
                  className={
                    ['draft', 'quoted', 'waiting', 'accepted', 'sent'].includes(status)
                      ? ''
                      : css.hiddenButton
                  }
                  style={{ marginTop: 10 }}
                  colorType="error"
                  height="short"
                  variant="contained"
                  onClick={() => rejectQuote()}
                  fullWidth
                  disabled={isEither(status, 'waiting', 'draft')}
                  isLoading={isRejectLoading}
                >
                  {t('quote.reject')}
                </Button>
              </>
            )}
        </div>
      ) : canEnterTheActualPurchasePrice && !isFromMultiquotation ? (
        <ActualPurchasePriceModal
          actionButtonContent={t('quote.accept')}
          modalValidateButtonContent={t('quote.save')}
          isDisabled={status !== 'quoted' && status !== 'rejected'}
          isDisplaySkipThisStepButton
          mustExecuteAcceptQuoteQuery
        />
      ) : (
        !isFromMultiquotation && (
          <AcceptAction
            id={id}
            content={t('quote.accept')}
            isDisabled={status !== 'quoted' && status !== 'rejected'}
          />
        )
      )}
      <Alert {...alertOptions} />
    </Card>
  ) : (
    <Card>
      <QuoteInfos
        status={status}
        subStatus={subStatus}
        withdrawalSite={withdrawalSite}
        isDisplayUpdateFleetStatus={isDisplayUpdateFleetStatus}
      />
      <Typography variant="body2">{t('quote.quoteExpired')}</Typography>
      <Divider style={{ margin: '1.5rem 0', width: '100%' }} />
      {b2cAdvisedPrice && b2cAdvisedPrice?.amount !== quoterPrice?.amount && (
        <Typography variant="body1">
          <span className={css.panelLabel}>{t('quote.advisedPrice')} : </span>
          {`${numeral(b2cAdvisedPrice?.amount).format()} €`}
        </Typography>
      )}
      <Typography variant="body1">
        <span className={css.panelLabel}>
          {quoterPrice?.isEstimate ? t('quote.estimate') : t('quote.commitmentPrice')} :{' '}
        </span>
        {quoterPrice ? `${numeral(quoterPrice?.amount).format()} €` : '-'}
      </Typography>
      <QuoterPriceNotMarged quoterPrice={quoterPriceNotMarged} rights={rights} />

      {canDuplicateQuote && !isFromMultiquotation && (
        <>
          <Divider style={{ margin: '1.5rem 0', width: '100%' }} />

          <DuplicateRequestAction id={id} />
        </>
      )}
    </Card>
  )
}

const QuoteActionsPanelCmp = withStyles(() => ({
  '@media (orientation:portrait)': { wrapper: { width: '100%' } },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoTag: {
    display: 'inline',
    width: 'fit-content',
    padding: '0px 10px',
    margin: '20px 0px',
  },
  actionButtons: {
    extend: 'columnFlex',
  },
  hiddenButton: { display: 'none' },
  panelLabel: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#868F98',
    margin: '0.5rem 0',
  },
  panelLabelDisalbled: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#868F98',
    margin: '0.5rem 0',
  },
  noCommitmentPrice: {
    color: '#868F98',
    margin: '0.5rem 0',
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  requirements: {
    fontStyle: 'italic',
    margin: '1rem 0',
  },
  quoteActionTitleStyle: { fontSize: '1.10rem', color: '#868F98' },
}))(QuoteActionsPanelJSX)

const handleWithdrawalSiteChange =
  ({ areSitesIdentical, setAreSitesIdentical, setField, onChange }) =>
  (e, type) => {
    onChange(e)
    setField('withdrawalType', type)
    if (type !== 'both') {
      setField('billingSite', '')
      setAreSitesIdentical(false)
    }
    if (areSitesIdentical) {
      setField('billingSite', e.target.value)
    }
  }

const handleIdenticalCheck =
  ({ setAreSitesIdentical, setField, field }) =>
  e => {
    setField('billingSite', e.target.checked ? field('withdrawalSite') : '')
    setAreSitesIdentical(e.target.checked)
  }

const WithdrawalModalJSX = ({
  classes: css,
  initialWithdrawalSitesResponse,
  initialBillingSitesResponse,
  field,
  onChange,
  setField,
  enquirerGroup,
  withdrawalSite,
  billingSite,
}: {
  classes: Object,
  initialWithdrawalSitesResponse: Object,
  initialBillingSitesResponse: Object,
  field: Function,
  onChange: Function,
  setField: Function,
  enquirerGroup: Object,
  withdrawalSite?: Object,
  billingSite?: Object,
}) => {
  const { t } = useTranslation()

  const [areSitesIdentical, setAreSitesIdentical] = useState(false)

  const [withdrawalSites, setWithdrawalSites] = useState(initialWithdrawalSitesResponse?.results)
  const [billingSites, setBillingSites] = useState(initialBillingSitesResponse?.results)
  // Using Apollo because using a withRedux on WithdrawalModalCmp was preventing re-renders.
  // Maybe because nested withRedux is problematic, or because the modal is created
  // imperatively which may be a problem for the normal React re-rendering flow.
  const [fetchBillingSites, { loading: areBillingSitesLoading }] = useLazyQuery(listSitesQuery, {
    onCompleted: data => {
      setBillingSites(data?.list_sites?.results)
    },
  })
  const [fetchWithdrawalSites, { loading: areWithdrawalSitesLoading }] = useLazyQuery(
    listSitesQuery,
    {
      onCompleted: data => {
        setWithdrawalSites(data?.list_sites?.results)
      },
    },
  )

  const hasMoreThanOneWithdrawalPage = initialWithdrawalSitesResponse?.nbPages > 1
  const hasMoreThanOneBillingPage = initialBillingSitesResponse?.nbPages > 1

  useEffect(() => {
    if (withdrawalSite) {
      setField('withdrawalSite', withdrawalSite.id)
    }
    if (billingSite) {
      setField('billingSite', billingSite.id)
    }
  }, [withdrawalSite, billingSite, setField])

  return (
    <div>
      <FormControl component="fieldset" className={css.formControl}>
        <FormLabel component="legend" className={css.formControlTitle}>
          {t('quote.withdrawalSite')}
        </FormLabel>
        <Divider />
        {hasMoreThanOneWithdrawalPage && (
          <SitesFilterSection
            lazyQuery={fetchWithdrawalSites}
            type="withdrawal"
            enquirerGroup={enquirerGroup}
          />
        )}
        {areWithdrawalSitesLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Progress size={40} />
          </div>
        ) : (
          <>
            <RadioGroup aria-label="gender" name="withdrawalSite" value={field('withdrawalSite')}>
              {withdrawalSites &&
                withdrawalSites.map(ws => (
                  <FormControlLabel
                    value={ws.id}
                    control={<Radio color="secondary" />}
                    label={<Typography variant="body2">{ws.name}</Typography>}
                    labelPlacement="end"
                    key={ws.id}
                    onChange={e =>
                      handleWithdrawalSiteChange({
                        areSitesIdentical,
                        onChange,
                        setField,
                        setAreSitesIdentical,
                      })(e, ws.type)
                    }
                  />
                ))}
            </RadioGroup>
            <FormControlLabel
              checked={areSitesIdentical}
              control={<Checkbox color="secondary" />}
              label={
                <>
                  <Typography variant="body2">
                    {t('quote.identicalBillingAndDeliverySite')}
                  </Typography>
                  {field('withdrawalType') === 'withdrawal' && (
                    <Typography variant="caption">{t('quote.notABillingSite')}</Typography>
                  )}
                </>
              }
              labelPlacement="end"
              onChange={handleIdenticalCheck({ setAreSitesIdentical, setField, field })}
              disabled={field('withdrawalType') !== 'both'}
              classes={{ disabled: css.disabledLabel }}
            />
          </>
        )}
      </FormControl>
      <FormControl component="fieldset" className={css.formControl}>
        <FormLabel component="legend" className={css.formControlTitle}>
          {t('quote.billingSite')}
        </FormLabel>
        <Divider />
        {hasMoreThanOneBillingPage && (
          <SitesFilterSection
            lazyQuery={fetchBillingSites}
            type="billing"
            enquirerGroup={enquirerGroup}
          />
        )}
        {areBillingSitesLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Progress size={40} />
          </div>
        ) : (
          <RadioGroup
            aria-label="gender"
            name="billingSite"
            value={field('billingSite')}
            {...{ onChange }}
          >
            {billingSites &&
              billingSites.map(bs => (
                <FormControlLabel
                  value={bs.id}
                  control={<Radio color="secondary" />}
                  label={<Typography variant="body2">{bs.name}</Typography>}
                  labelPlacement="end"
                  key={bs.id}
                  disabled={areSitesIdentical}
                />
              ))}
          </RadioGroup>
        )}
      </FormControl>
    </div>
  )
}

const WithdrawalModalCmp = withStyles(({ breakpoints, palette }) => ({
  formControl: {
    marginTop: 20,
    [breakpoints.down('sm')]: { width: '90%' },
    [breakpoints.up('md')]: {
      minWidth: 400,
    },
  },
  formControlTitle: {
    color: palette.secondary.dark,
    marginBottom: 10,
  },
  disabledLabel: {
    opacity: 0.7,
  },
}))(WithdrawalModalJSX)

const QuoteActionsPanelStandard: any = compose(
  withRedux(({ user }) => ({
    rights: user.data.rights,
    displayPrice: user.data?.config?.pricing?.display,
    canEnterACommercialProposal: user.data?.config?.pricing?.tradein_offer?.activated ?? false,
    canEnterTheActualPurchasePrice: user.data?.config?.pricing?.tradein_price?.activated ?? false,
    tradeinOfferConfiguration: user.data?.config?.pricing?.tradein_offer ?? {},
    userId: user.data.id,
  })),
  withFields(),
  withRouter,
)(QuoteActionsPanelCmp)
export default QuoteActionsPanelStandard
