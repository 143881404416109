// @flow

import React from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'
import TextField from '@autodisol/ads-js/components/Inputs/ReactHookForm/TextField'
import AlertMessageZone from '@autodisol/ads-js/components/AlertMessage/AlertMessageZone'
import { getErrorsFormatted } from '@autodisol/ads-js/utils/reactHookForm/errors'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { getLanguage } from 'utils/language'
import i18next from 'services/translations/config'
import { MIN_KM, MAX_KM } from 'quote/cmp/validationRules'
import { validateStringNumber } from 'utils/reactHookForm'
import { MODES } from 'utils/constants/modes'
import FieldLabel from 'quote/cmp/FieldLabel'

import type { Mode } from 'types/modes'

const styles = ({ breakpoints }) => ({
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '.875em',
    marginBottom: 50,
    [breakpoints.up('sm')]: {
      margin: '0 50px 28px',
    },
  },
  form: {
    padding: '0 20px',
    '& > div:not(div:last-child)': {
      marginBottom: 30,
    },
    '& > div > p': {
      color: '#A8B4BCad',
    },
    [breakpoints.up('sm')]: {
      padding: 0,
      '& > div': {
        margin: '0 50px 28px',
        width: 'calc(100% - 100px)',
      },
      '& > div:last-child': {
        marginBottom: 0,
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: '0 20px 10px',
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& button': {
        padding: '10px 40px',
        margin: 0,
      },
      '& button:first-child': {
        marginRight: 10,
      },
      '& button:last-child': {
        marginLeft: 10,
      },
    },
  },
  errors: {
    margin: '0 0 20px',
    [breakpoints.up('sm')]: {
      margin: '0 50px 20px',
    },
  },
})

const MIN_DELIVERY_DATE = moment().format('YYYY-MM-DD')
const language = getLanguage()

const validationSchema = z
  .object({
    currentKm: validateStringNumber({
      invalidValue: i18next.t('quote.duplicate.form.error.kmToStart.invalidValue'),
      message: i18next.t('quote.duplicate.form.error.kmToStart.message'),
      min: MIN_KM,
      max: MAX_KM,
    }),
    deliveryDate: z.coerce
      .date({
        errorMap: (issue, ctx) => {
          if (issue.code === 'invalid_date')
            return {
              message: i18next.t('quote.duplicate.form.error.deliveryDate.invalidValue'),
            }
          return { message: ctx.defaultError }
        },
      })
      .min(new Date(MIN_DELIVERY_DATE), {
        message: i18next.t('quote.duplicate.form.error.deliveryDate.message', {
          date: new Date(MIN_DELIVERY_DATE).toLocaleDateString(language),
        }),
      }),
    deliveryKm: z
      .string()
      .min(1, i18next.t('quote.duplicate.form.error.deliveryKm.invalidValue'))
      .regex(/^[-+]?[0-9]+$/, i18next.t('quote.duplicate.form.error.deliveryKm.invalidValue'))
      .transform(s => Number(s)),
    deliveryHour: z.string().optional(),
    feedbackDuplicateRequest: z.string().nullable(),
  })
  .superRefine((data, ctx) => {
    const deliveryKm = Number(data.deliveryKm)
    const currentKm = Number(data.currentKm)

    if (deliveryKm < currentKm || deliveryKm > MAX_KM) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18next.t('quote.duplicate.form.error.deliveryKm.message', {
          min: currentKm,
        }),
        path: ['deliveryKm'],
      })
    }
  })

type ValidationSchemaResval = z.infer<typeof validationSchema>

type OnSubmitParams = {
  currentKm: number,
  deliveryDate: string | Date,
  deliveryKm: number,
  deliveryHour?: string,
  feedbackDuplicateRequest: string,
}

type Props = {
  classes: Object,
  id: string,
  handleCloseModal: () => void,
  mode: Mode,
  duplicateQuote: Function,
  quoteData: Object,
  isLoading: boolean,
}

const StandardForm = ({
  classes,
  id,
  handleCloseModal,
  mode,
  duplicateQuote,
  quoteData,
  isLoading,
}: Props) => {
  const { t } = useTranslation()
  const { currentKm, deliveryDate, deliveryKm, deliveryHour } = quoteData

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ValidationSchemaResval>({
    resolver: zodResolver(validationSchema),
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data: OnSubmitParams) => {
    duplicateQuote({
      variables: {
        id,
        quoteInput: {
          category: mode,
          currentKm: data.currentKm,
          deliveryDate: {
            date: data.deliveryDate,
            timezone: 'UTC',
          },
          deliveryKm: data.deliveryKm,
          deliveryHour:
            data?.deliveryHour !== '' && data?.deliveryHour !== undefined
              ? Number(data.deliveryHour)
              : undefined,
        },
        comment: data.feedbackDuplicateRequest,
      },
    })
  }

  return (
    <>
      <Modal.Header>{t('quote.duplicate.request')}</Modal.Header>
      <Modal.Content>
        <p className={classes.description}>
          {t('quote.duplicate.editableInformationForCreateDuplicate')}
        </p>

        <form id="duplicateForm" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            control={control}
            name="currentKm"
            defaultValue=""
            type="number"
            label={<FieldLabel label={t('quote.currentMileage')} isRequired />}
            placeholder={`${t('globals.exampleColon')} 1000`}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.currentKm', {
              km: currentKm,
            })}
          />
          <TextField
            control={control}
            name="deliveryDate"
            defaultValue=""
            type="date"
            label={<FieldLabel label={t('quote.deliveryDate')} isRequired />}
            inputProps={{
              min: MIN_DELIVERY_DATE,
            }}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.deliveryDate', {
              date: new Date(deliveryDate.date).toLocaleDateString(language),
            })}
          />
          <TextField
            control={control}
            name="deliveryKm"
            defaultValue=""
            type="number"
            label={<FieldLabel label={t('quote.deliveryMileage')} isRequired />}
            placeholder={`${t('globals.exampleColon')} 1000`}
            helperText={i18next.t('quote.duplicate.form.fields.helperText.delieryMileage', {
              km: deliveryKm,
            })}
          />

          {mode === MODES.standard_bike && (
            <TextField
              control={control}
              name="deliveryHour"
              defaultValue=""
              type="number"
              label={t('quote.numberOfEngineHours')}
              placeholder={`${t('globals.exampleColon')} 1000`}
              helperText={i18next.t('quote.duplicate.form.fields.helperText.deliveryHour', {
                hour: deliveryHour,
              })}
            />
          )}

          <TextField
            variant="standard"
            control={control}
            name="feedbackDuplicateRequest"
            defaultValue=""
            type="text"
            label={t('quote.duplicate.form.fields.feedback')}
            placeholder={t('quote.generalFeedbackPlaceholder')}
            multiline
            rows={3}
          />
        </form>
      </Modal.Content>
      <Modal.Actions>
        {Object.keys(errors).length > 0 && (
          <div className={classes.errors}>
            <AlertMessageZone messages={getErrorsFormatted(errors)} />
          </div>
        )}

        <div className={classes.buttons}>
          <Button
            colorType="secondary"
            height="short"
            variant="contained"
            onClick={handleCloseModal}
          >
            {t('globals.cancel')}
          </Button>
          <Button
            form="duplicateForm"
            colorType="green"
            height="short"
            variant="contained"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t('quote.duplicate.request')}
          </Button>
        </div>
      </Modal.Actions>
    </>
  )
}

export default (withStyles(styles)(StandardForm): any)
