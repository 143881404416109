// @flow

import { defaultNbElementByPage, defaultPage } from '@autodisol/ads-js/components/Pagination'
import { useQuery } from '@apollo/client'
import { getMultiQuotationQuery } from 'quote/quote-queries'
import { useTranslation } from 'react-i18next'

import type { Mode } from 'types/modes'

const getMultiQuotationCategories = (mode: Mode) => {
  if (mode === 'standard_bike') {
    return ['standard_bike']
  }

  return ['standard', 'resval']
}

type Params = {
  vin?: string,
  canViewMultiquotation: boolean,
  excludeId?: string,
  mode: Mode,
  isFromMultiquotation?: boolean,
}

type ReturnType = {
  isLoadingMultiquotation: boolean,
  multiquotation: Object[],
  handlePagination: Function,
  multiquotationResults: number,
  currentPage: number,
  rowsPerPage: number,
}

export const getFormattedPrice = (prices: Object[]): Object => {
  const formattedPrices = {}

  prices.forEach(price => {
    formattedPrices[price.type] = parseInt(price.amount, 10)
  })

  return formattedPrices
}

export const useMultiquotation = ({
  vin,
  canViewMultiquotation,
  excludeId,
  mode,
  isFromMultiquotation,
}: Params): ReturnType => {
  const { t } = useTranslation()

  const categories = getMultiQuotationCategories(mode)

  const {
    data,
    refetch,
    loading: isLoadingMultiquotation,
  } = useQuery(getMultiQuotationQuery, {
    variables: {
      filters: {
        categories,
        excludeId,
        vin,
      },
      page: defaultPage,
      nbElementByPage: defaultNbElementByPage,
    },
    notifyOnNetworkStatusChange: true,
    skip: !vin || !canViewMultiquotation || isFromMultiquotation,
  })

  const searchMultiquotation = data?.search_multiquotation
  const multiquotation = searchMultiquotation?.results ?? []

  const multiquotationParsed = multiquotation.map(element => {
    const quoterPrice = element.prices && element.prices.find(price => price.type === 'quoter')
    const quotationDate = quoterPrice?.createdAt.date ?? null

    return {
      ...element,
      formattedPrices: getFormattedPrice(element.prices ?? []),
      quotationDate,
      takenBy:
        quoterPrice && quoterPrice.user
          ? quoterPrice.user.fullname
          : t('quote.valuedAutomatically'),
    }
  })

  const handlePagination = () => (page, nbElementByPage) => {
    refetch({
      filters: {
        categories,
        excludeId,
        vin,
      },
      page,
      nbElementByPage,
    })
  }

  return {
    isLoadingMultiquotation,
    multiquotation: multiquotationParsed,
    handlePagination,
    multiquotationResults: searchMultiquotation?.nbResults ?? 0,
    currentPage: searchMultiquotation?.currentPage ?? 0,
    rowsPerPage: searchMultiquotation?.nbElementByPage ?? 0,
  }
}
