// @flow

import React, { useState } from 'react'
import { connect as withRedux } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@autodisol/ads-js/components/CustomButton'
import Modal from 'Modal'
import Confirmation from 'quote/cmp/QuoteActionsPanel/DuplicateRequestAction/Confirmation'
import ResvalForm from 'quote/cmp/QuoteActionsPanel/DuplicateRequestAction/ResvalForm'
import StandardForm from 'quote/cmp/QuoteActionsPanel/DuplicateRequestAction/StandardForm'
import { RIGHT_CREATE_QUOTE } from '_client/config'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { useDuplicateRequest } from 'quote/cmp/QuoteActionsPanel/DuplicateRequestAction/useDuplicateRequest'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import type { Mode } from 'types/modes'

type Props = {
  id: string,
  mode: Mode,
  rights: string[],
  history: Object,
}

const DuplicateRequestAction = ({ id, rights, mode, history }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const { quoteData, duplicateQuote, isLoading } = useDuplicateRequest({ id, history })

  const [show, setShow] = useState<'confirm' | 'form'>('confirm')

  const hasRightToCreateQuote = rights.includes(RIGHT_CREATE_QUOTE)

  const handleConfirm = () => {
    setShow('form')
  }

  const handleOpenModal = () => {
    setShow('confirm')
    openModal()
  }

  const handleCloseModal = () => {
    if (isLoading) return

    closeModal()
  }

  if (!hasRightToCreateQuote) return null

  return (
    <>
      <Button
        colorType="yellow"
        height="short"
        onClick={handleOpenModal}
        fullWidth
        variant="contained"
        style={{ margin: '6px 0 12px' }}
      >
        {t('quote.duplicate.request')}
      </Button>

      {open && (
        <Modal
          open={open}
          handleClose={handleCloseModal}
          ariaLabelledby="duplicate the request"
          ariaDescribedby="duplicate of the quotation"
        >
          {show === 'confirm' ? (
            <Confirmation
              handleConfirm={handleConfirm}
              handleCloseModal={handleCloseModal}
              mode={mode}
            />
          ) : (
            <>
              {mode === MODES.resval ? (
                <ResvalForm
                  handleCloseModal={handleCloseModal}
                  id={id}
                  quoteData={quoteData}
                  duplicateQuote={duplicateQuote}
                  isLoading={isLoading}
                />
              ) : (
                <StandardForm
                  handleCloseModal={handleCloseModal}
                  id={id}
                  mode={mode}
                  quoteData={quoteData}
                  duplicateQuote={duplicateQuote}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </Modal>
      )}
    </>
  )
}

export default (compose(
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      rights: user.data.rights,
      mode: currentMode,
    }
  }),
  withRouter,
)(DuplicateRequestAction): any)
